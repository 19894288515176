import { useQuery } from '@tanstack/react-query';
import { createFileRoute, LinkProps } from '@tanstack/react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/Accordion/Accordion';
import AddToCartButton from '@/components/AddToCartButton/AddToCartButton';
import AddToFavoritesButton from '@/components/AddToFavoritesButton/AddToFavoritesButton.tsx';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.tsx';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import Conditional from '@/components/Conditional/Conditional.tsx';
import ImageGallery from '@/components/ImageGallery/ImageGallery';
import ItemOptions from '@/components/ItemOptions/ItemOptions';
import LastViewed from '@/components/LastViewed/LastViewed';
import NumberInput from '@/components/NumberInput/NumberInput';
import Price from '@/components/Price/Price.tsx';
import RelatedItems from '@/components/RelatedItems/RelatedItems';
import Reviews from '@/components/Reviews/Reviews';
import Skeleton from '@/components/Skeleton/Skeleton';
import Stars from '@/components/Stars/Stars';
import { ItemOption as ItemOptionType } from '@/customTypes/itemResponse';
import { queryClient, useCacheStore, useStateStore } from '@/stores';
import { pushEvent } from '@/utils/GTM.ts';
import { getItemInfo } from '@/utils/itemFunctions.tsx';

import styles from './$itemURL.module.css';

const renderHtmlToText = (htmlString: string) => {
	const temporaryDiv = document.createElement('div');
	temporaryDiv.innerHTML = htmlString;
	return temporaryDiv.textContent;
};

// TODO split this function
const ProductDetailScreen: React.FC = () => {
	const addToLastViewed = useStateStore(store => store.addToLastViewed);
	const { itemURL } = Route.useParams();
	const search = Route.useSearch();
	const [quantity, setQuantity] = useState(1);
	const simplifiedItem = useCacheStore(store => store.pdpSimplifiedItem);
	const { data, error } = useQuery({
		notifyOnChangeProps: ['data'],
		queryFn: () => getItemInfo(itemURL),
		queryKey: ['item', itemURL],
		staleTime: 1000 * 60 * 10,
	});
	// TODO move this to a custom hook and also use simplifiedItem
	const optionsByKey = useMemo(() => {
		const result: Record<string, ItemOptionType> = {};
		for (const option of data?.options ?? []) {
			result[option.internalid.replace('custcol_', '')] = option;
		}
		return result;
	}, [data?.options]);
	const optionValues = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of Object.keys(search)) {
			result[option] = optionsByKey[option]?.values?.find(
				value => value.internalid === String(search[option]),
			)?.label;
		}
		return result;
	}, [search, optionsByKey]);
	const currentChild = useMemo(
		() =>
			data?.childItems.find(
				child =>
					Object.keys(optionValues).length > 0 &&
					Object.keys(optionValues).every(key => child.customFields[key] === optionValues[key]),
			) ?? data,
		[data, optionValues],
	);
	const descriptionString = useMemo(
		() => renderHtmlToText(currentChild?.description ?? data?.description ?? ''),
		[data?.description, currentChild?.description],
	);

	useEffect(() => {
		if (data) {
			addToLastViewed(data!);
			pushEvent({
				ecommerce: {
					items: [
						{
							item_id: data.internalId,
							item_name: data.name,
							price: data.price,
						},
					],
				},
				event: 'view_item',
			});
		}
	}, [addToLastViewed, data]);

	const reviewCount = Number(data?.customFields.ns_pr_count ?? 0);
	const InfoSection = (
		<>
			<h1 className={styles.productTitle}>
				<Conditional visible={data?.customFields.brand ?? simplifiedItem?.customFields.brand}>
					<span>{data?.customFields.brand ?? simplifiedItem?.customFields.brand}</span>
				</Conditional>
				{currentChild?.name ?? data?.name ?? simplifiedItem?.name ?? (
					<Skeleton lines={window.innerWidth < 600 ? 1 : 2} />
				)}
			</h1>
			<label className={styles.sku}>
				<span className={styles.skuLabel}>SKU: </span>
				{currentChild?.itemId ?? data?.itemId ?? simplifiedItem?.itemId ?? (
					<Skeleton width="20ch" />
				)}
			</label>
			<div className={styles.reviewsContainer}>
				<Stars value={Number(data?.customFields.ns_pr_rating ?? 5)} />
				{data ? (
					<span>
						{reviewCount > 0
							? reviewCount > 1
								? `${reviewCount} Reviews`
								: `${reviewCount} Review`
							: 'No Reviews Yet'}
					</span>
				) : (
					<Skeleton height="2ch" lines={1} width="10ch" />
				)}
			</div>
		</>
	);

	const DescriptionSection = (
		<Accordion collapsible={true} defaultValue="description" type="single">
			<AccordionItem value="description">
				<AccordionTrigger>
					<h2>Description</h2>
				</AccordionTrigger>
				<AccordionContent
					dangerouslySetInnerHTML={data?.description ? { __html: data?.description } : undefined}
				>
					<Conditional visible={!data?.description}>
						<Skeleton lines={3} />
					</Conditional>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
	// TODO if we got simplified item and not data show Home > Loading... > Item
	const breadcrumb = useMemo(
		() => (
			<Breadcrumb
				links={[
					{ children: 'Home', to: '/' },
					...(data?.categories?.map(
						category =>
							({
								children: category.name,
								params: { _splat: category.url },
								to: '/category/$',
							}) as LinkProps,
					) ?? []),
					{
						children: data?.name ?? simplifiedItem?.name ?? `Loading: ${itemURL}`,
						params: { itemURL: itemURL },
						to: '/item/$itemURL',
					},
				]}
			/>
		),
		[data?.categories, data?.name, itemURL, simplifiedItem?.name],
	);

	if (error) {
		return <span>Invalid product ({error.message})</span>;
	}

	return (
		<main className="containerGrid">
			<Helmet>
				<title>{data?.name ?? simplifiedItem?.name ?? `Loading: ${itemURL}`}</title>
				<meta content={descriptionString ?? 'Loading...'} name="description" />
			</Helmet>
			{breadcrumb}
			<div className={styles.topContainer}>
				<div className={styles.imageContainer}>
					<Breakpoint maxWidth={675}>{InfoSection}</Breakpoint>
					<ImageGallery item={data ?? simplifiedItem} options={optionValues} />
				</div>

				<div className={styles.detailSection}>
					<Breakpoint minWidthExclude={675}>{InfoSection}</Breakpoint>
					<Price item={currentChild ?? simplifiedItem} size="large" />

					<div className={styles.quantityContainer}>
						<span className={styles.quantityText}>Quantity:</span>
						<NumberInput
							disabled={!data}
							max={999_999}
							min={1}
							setValue={setQuantity}
							value={quantity}
						/>
					</div>
					<ItemOptions
						childItems={data?.childItems ?? []}
						options={data?.options ?? []}
						optionsByKey={optionsByKey}
						search={search}
					/>
					<div className={styles.buttonWrapper}>
						<AddToCartButton
							item={data}
							optionValues={optionValues}
							quantity={quantity}
							setQuantity={setQuantity}
							toAdd={currentChild}
						/>
						<AddToFavoritesButton item={currentChild} />
					</div>
					<span
						className={`${styles.outOfStock} ${!currentChild || currentChild.inStock || currentChild.isBackorderable ? 'hidden' : ''}`}
					>
						Out of stock
					</span>
					{DescriptionSection}
				</div>
			</div>
			<span className={styles.freeShipping}>Free Shipping on orders over $50</span>
			<Reviews item={data} />
			<div className={styles.lastViewedWrapper}>
				<h3>Last Viewed</h3>
				<LastViewed />
			</div>
			<RelatedItems internalId={data?.internalId ?? simplifiedItem?.internalId} />
		</main>
	);
};

export const Route = createFileRoute('/item/$itemURL')({
	component: ProductDetailScreen,
	loader: ({ params }) => {
		queryClient.prefetchQuery({
			queryFn: () => getItemInfo(params.itemURL),
			queryKey: ['item', params.itemURL],
		});
	},
	validateSearch: (search: Record<string, any>) => search,
});
