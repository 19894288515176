import React from 'react';

import { useStateStore } from '@/stores';

export const Breakpoint: React.FC<{
	children: React.ReactNode;
	maxWidth?: number;
	maxWidthExclude?: number;
	minWidth?: number;
	minWidthExclude?: number;
}> = ({ children, maxWidth, maxWidthExclude, minWidth, minWidthExclude }) => {
	const visible = useStateStore(
		({ state: { pageWidth } }) =>
			(minWidth === undefined || pageWidth >= minWidth) &&
			(maxWidth === undefined || pageWidth <= maxWidth) &&
			(minWidthExclude === undefined || pageWidth > minWidthExclude) &&
			(maxWidthExclude === undefined || pageWidth < maxWidthExclude),
	);
	if (visible) {
		return children;
	}
	return null;
};

export default Breakpoint;
