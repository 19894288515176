import React from 'react';

import Skeleton from '@/components/Skeleton/Skeleton.tsx';
import { Summary } from '@/customTypes/Order';

import styles from './OrderSummary.module.css';

const SummaryRow: React.FC<{
	isTotal?: boolean;
	label: string;
	value?: string;
}> = ({ isTotal, label, value }) => (
	<div className={`${styles.summaryRow} ${isTotal ? styles.totalRow : ''}`}>
		<span>{label}</span>
		<span>{value ?? <Skeleton width={100} />}</span>
	</div>
);

// Helper function to determine if a field should be shown
const shouldShowField = (value?: number, isRequired = false) => isRequired || value;

const OrderSummary: React.FC<{ summary?: Summary }> = ({ summary }) => (
	<div className={styles.orderSummary}>
		<div className={styles.header}>
			<h2 className={styles.title}>Order Summary</h2>
			<span className={styles.itemCount}>
				{summary ? (
					`${summary.itemcount} ${summary.itemcount === 1 ? 'item' : 'items'}`
				) : (
					<Skeleton width={100} />
				)}
			</span>
		</div>
		<div className={styles.content}>
			{/* Subtotal section */}
			<SummaryRow label="Subtotal" value={summary?.subtotal_formatted} />

			{/* Discounts section */}
			{shouldShowField(summary?.discounttotal) ? (
				<>
					<SummaryRow label="Discount" value={`-${summary?.discounttotal_formatted}`} />
					{shouldShowField(summary?.discountrate) ? (
						<div className={styles.discountRate}>Rate: {summary?.discountrate_formatted}</div>
					) : null}
				</>
			) : null}

			{/* Gift Certificate */}
			{shouldShowField(summary?.giftcertapplied) ? (
				<SummaryRow
					label="Gift Certificate Applied"
					value={`-${summary?.giftcertapplied_formatted}`}
				/>
			) : null}

			{/* Shipping & Handling */}
			{shouldShowField(summary?.shippingcost) ? (
				<SummaryRow label="Shipping" value={summary?.shippingcost_formatted} />
			) : null}
			{shouldShowField(summary?.handlingcost) ? (
				<SummaryRow label="Handling" value={summary?.handlingcost_formatted} />
			) : null}

			{/* Taxes */}
			{shouldShowField(summary?.totalcombinedtaxes) ? (
				<>
					<div className={styles.spacer} />
					<SummaryRow label="Total Tax" value={summary?.totalcombinedtaxes_formatted} />
					{shouldShowField(summary?.taxonshipping) ? (
						<div className={styles.taxDetail}>Shipping Tax: {summary?.taxonshipping_formatted}</div>
					) : null}
					{shouldShowField(summary?.taxonhandling) ? (
						<div className={styles.taxDetail}>Handling Tax: {summary?.taxonhandling_formatted}</div>
					) : null}
					{shouldShowField(summary?.taxondiscount) ? (
						<div className={styles.taxDetail}>Discount Tax: {summary?.taxondiscount_formatted}</div>
					) : null}
				</>
			) : null}

			{/* Total */}
			<div className={styles.spacer} />
			<SummaryRow isTotal={true} label="Total" value={summary?.total_formatted} />
		</div>
	</div>
);

export default OrderSummary;
