import React, { useCallback, useEffect, useState } from 'react';

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
	CarouselProperties,
} from '@/components/Carousel/Carousel';
import ItemComponent from '@/components/ItemComponent/ItemComponent';
import { Item } from '@/customTypes/Item';

import styles from './ItemCarousel.module.css';

export type ItemCarouselProperties = {
	gap?: number;
	items?: Item[];
	loadingAmount?: number;
	maxItemWidth?: number;
	minItemWidth?: number;
} & CarouselProperties &
	React.HTMLAttributes<HTMLDivElement>;

const ItemCarousel: React.FC<ItemCarouselProperties> = ({
	gap = 16,
	items,
	loadingAmount,
	maxItemWidth = 250,
	minItemWidth = 220,
	...properties
}) => {
	const [slidesPerView, setSlidesPerView] = useState(1);
	const contentReference = React.useRef<HTMLDivElement>(null);

	const calculateSlidesPerView = useCallback(
		(containerWidth: number) => {
			// Account for gaps in the width calculation
			const calculateWidthWithGaps = (numberSlides: number) => {
				const totalGapsWidth = gap * (numberSlides - 1);
				const availableWidth = containerWidth - totalGapsWidth;
				return availableWidth / numberSlides;
			};

			const maxPossibleSlides = Math.floor((containerWidth + gap) / (minItemWidth + gap));
			const minPossibleSlides = Math.max(
				1,
				Math.floor((containerWidth + gap) / (maxItemWidth + gap)),
			);

			for (let index = maxPossibleSlides; index >= minPossibleSlides; index--) {
				const itemWidth = calculateWidthWithGaps(index);
				if (itemWidth >= minItemWidth && itemWidth <= maxItemWidth) {
					return index;
				}
			}
			return minPossibleSlides;
		},
		[minItemWidth, maxItemWidth, gap],
	);

	useEffect(() => {
		const updateSize = () => {
			if (contentReference.current) {
				const width = contentReference.current.getBoundingClientRect().width;
				setSlidesPerView(calculateSlidesPerView(width));
			}
		};

		updateSize();
		window.addEventListener('resize', updateSize, { passive: true });
		return () => window.removeEventListener('resize', updateSize);
	}, [calculateSlidesPerView]);

	return (
		<Carousel
			{...properties}
			opts={{
				align: 'start',
				dragFree: true,
				loop: true,
			}}
			ref={contentReference}
		>
			<CarouselContent>
				{(items ?? Array.from({ length: loadingAmount ?? 0 })).map((item, index) => (
					<CarouselItem
						className={styles.item}
						key={index}
						style={{
							maxWidth: `calc(${100 / slidesPerView}% - ${(gap * (slidesPerView - 1)) / slidesPerView}px)`,
							// @ts-expect-error
							'padding-right': `${gap}px`,
						}}
					>
						<ItemComponent item={item} key={index} />
					</CarouselItem>
				))}
			</CarouselContent>
			<CarouselPrevious />
			<CarouselNext />
		</Carousel>
	);
};

export default ItemCarousel;
