import { Link } from '@tanstack/react-router';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

import Button from '@/components/Button/Button';
import CartItem from '@/components/CartItem/CartItem';
import Conditional from '@/components/Conditional/Conditional.tsx';
import GoToCheckoutButton from '@/components/GoToCheckoutButton/GoToCheckoutButton.tsx';
import { Summary } from '@/customTypes/Order';
import { useStateStore } from '@/stores';
import { useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './MiniCart.module.css';

const MiniCart: React.FC = () => {
	const lines = useOrderQuery(order => order?.lines) ?? [];
	const summary = useOrderQuery(order => order?.summary) ?? ({} as Summary);
	const reference = useRef<HTMLDivElement>(null);
	const open = useStateStore(state => state.state.cartOpen);
	const setState = useStateStore(state => state.setState);

	useEffect(() => {
		const listener = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			if (
				!reference.current?.contains(target) &&
				!document.querySelector('#cart-button')?.contains(target)
			) {
				setState({ cartOpen: false });
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, [setState]);

	return ReactDOM.createPortal(
		<RemoveScroll enabled={open}>
			<div className={`${styles.wrapper} ${open ? styles.open : ''}`}>
				<menu className={styles.container} ref={reference} tabIndex={-1}>
					<div className={styles.topContainer}>
						<label className={styles.totalItems}>Total Items: {summary.itemcount}</label>
						<Conditional visible={lines.length > 0}>
							<Button asChild size="sm" variant="outline">
								<Link
									onClick={() => {
										setState({ cartOpen: false });
									}}
									params={{}}
									search={{}}
									to="/cart"
								>
									Edit Cart
								</Link>
							</Button>
						</Conditional>
					</div>
					<div className={styles.itemsContainer}>
						{summary.itemcount === 0 ? (
							<span>No items in the cart</span>
						) : (
							<div>
								{lines.map(line => (
									<CartItem
										isMiniCart={true}
										key={line.internalid}
										line={line}
										onClick={() => setState({ cartOpen: false })}
									/>
								))}
							</div>
						)}
					</div>

					<div className={styles.subtotalWrapper}>
						<span className={styles.subtotalText}>Subtotal: {summary.total_formatted}</span>
						<GoToCheckoutButton onClick={() => setState({ cartOpen: false })} />
					</div>
				</menu>
			</div>
		</RemoveScroll>,
		document.querySelector('#root-modal')!,
	);
};

export default MiniCart;
