import { Item, SimplifiedItem } from '@/customTypes/Item';

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const deepMerge = <T extends object>(object1: T, object2: Partial<T> | T): T => {
	if (typeof object1 !== 'object' || typeof object2 !== 'object') return object1;

	if (Array.isArray(object1)) {
		return object2 as T;
	}

	const result: any = {};

	for (const key in object1) {
		result[key] = object1[key];
	}

	for (const key in object2) {
		if (object2[key] !== null && typeof object2[key] === 'object') {
			result[key] =
				key in object1 && object1[key] !== null
					? // @ts-expect-error ts unsafe function
						deepMerge(object1[key], object2[key])
					: object2[key];
		} else {
			result[key] = object2[key];
		}
	}

	return result;
};

export const formatCurrency = (value: number) =>
	value.toLocaleString('en-US', {
		currency: 'USD',
		style: 'currency',
	});

export const getItemToAdd = (
	item: Item,
	options: Record<string, string | undefined>,
): Item | SimplifiedItem | undefined => {
	if (item?.childItems.length === 0) {
		return item;
	}
	const optionAsLabels = Object.entries(options).map(([key, value]) => ({
		key,
		label: item.options
			?.find(option => option.internalid.replace('custcol_', '') === key)
			?.values?.find(optionValue => optionValue.internalid === String(value))?.label,
	}));

	return item.childItems.find(childItem =>
		optionAsLabels.every(option => childItem.customFields[option.key] === option.label),
	);
};

export const formatFacetUrl = (url: string) =>
	url
		.replace(/custitem_?/, '') // Remove 'custitem_' prefix
		.split('_') // Split by underscores
		.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
		.join(' ');

export const replaceString = (string_: string, ...replacements: string[]) => {
	let result = string_;
	let index = 1;
	for (const replacement of replacements) {
		result = result.replace('$' + index, replacement);
		index++;
	}
	return result;
};

export const getSearchKey = (search: Record<string, any>) =>
	Object.entries(search)
		.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
		.filter(([_, value]) => value !== undefined)
		.map(
			([key, value]) =>
				`${key}=${typeof value === 'object' ? JSON.stringify(value) : value.toString()}`,
		);
