import { Slot } from '@radix-ui/react-slot';
import React from 'react';

import styles from './Button.module.css';

export interface ButtonProperties extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	asChild?: boolean;
	size?: 'default' | 'icon' | 'lg' | 'sm';
	variant?: 'default' | 'destructive' | 'ghost' | 'link' | 'outline' | 'secondary';
}

const variantClasses = {
	default: styles.variantDefault,
	destructive: styles.variantDestructive,
	ghost: styles.variantGhost,
	link: styles.variantLink,
	outline: styles.variantOutline,
	secondary: styles.variantSecondary,
};

const sizeClasses = {
	default: styles.sizeDefault,
	icon: styles.sizeIcon,
	lg: styles.sizeLg,
	sm: styles.sizeSm,
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProperties>(
	(
		{ asChild = false, className = '', size = 'default', variant = 'default', ...properties },
		reference,
	) => {
		const Comp = asChild ? Slot : 'button';
		const variantClass = variantClasses[variant];
		const sizeClass = sizeClasses[size];

		const combinedClassName = `${styles.button} ${variantClass} ${sizeClass} ${className}`.trim();

		return <Comp className={combinedClassName} ref={reference} {...properties} />;
	},
);

Button.displayName = 'Button';

export default Button;
