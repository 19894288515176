import { IconClock, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';

import Button from '@/components/Button/Button.tsx';
import ImageShower from '@/components/ImageShower/ImageShower';
import ItemLink from '@/components/ItemLink/ItemLink.tsx';
import NumberInput from '@/components/NumberInput/NumberInput';
import Price from '@/components/Price/Price.tsx';
import { Line } from '@/customTypes/Order';
import { useCacheStore } from '@/stores';
import {
	useOrderLoading,
	useRemoveLineMutation,
	useUpdateLineMutation,
} from '@/stores/OrderHooks.ts';

import styles from './CartItem.module.css';

const CartItem: React.FC<{
	isMiniCart?: boolean;
	line: Line;
	onClick?: () => void;
}> = ({ isMiniCart, line, onClick }) => {
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const [mouseInside, mouseInsideSet] = React.useState(false);
	const loadingOrder = useOrderLoading();
	const updateLine = useUpdateLineMutation();
	const removeLine = useRemoveLineMutation();
	const [inputValue, setInputValue] = useState(line?.quantity ?? 1);

	useEffect(() => {
		if (!loadingOrder) {
			setInputValue(line!.quantity);
		}
	}, [line, loadingOrder]);

	useEffect(() => {
		if (line && !loadingOrder) {
			const timeout = setTimeout(() => {
				if (inputValue === line.quantity) {
					return line;
				}
				updateLine.mutate({ ...line, quantity: inputValue });
			}, 300);

			return () => clearTimeout(timeout);
		}
	}, [inputValue, line, loadingOrder, updateLine]);

	const optionValues = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.cartOptionId.replace('custcol_', '')] = option.value.label;
		}
		return result;
	}, [line?.options]);
	const optionIds = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.cartOptionId.replace('custcol_', '')] = option.value.internalid;
		}
		return result;
	}, [line?.options]);
	const optionValuesByLabel = useMemo(() => {
		const result: Record<string, string | undefined> = {};
		for (const option of line?.options ?? []) {
			result[option.label] = option.value.label;
		}
		return result;
	}, [line?.options]);

	return (
		<ItemLink
			className={`${isMiniCart ? styles.containerMiniCart : styles.container}`}
			disabled={loadingOrder}
			item={line?.item.parent ?? line?.item}
			onClick={() => {
				setSimplifiedItem(line.item);
				onClick?.();
			}}
			onMouseEnter={() => mouseInsideSet(true)}
			onMouseLeave={() => mouseInsideSet(false)}
			search={optionIds}
		>
			<ImageShower
				className={styles.image}
				item={line?.item}
				mouseInside={mouseInside}
				options={optionValues}
				resize={4}
			/>
			<div className={styles.details}>
				<label>{line?.item.name}</label>
				<Price item={line?.item} size="small" />
				{isMiniCart ? null : <label className={styles.qtyLabel}>Quantity:</label>}
				<div className={styles.qtyWrapperCart}>
					<NumberInput
						disabled={loadingOrder}
						min={1}
						setValue={setInputValue}
						value={inputValue}
					/>
				</div>
				<div className={styles.optionsWrapper}>
					<div className={styles.options}>
						{isMiniCart ? (
							<label className={styles.qtyLabel}>Quantity: {line?.quantity}</label>
						) : null}

						{Object.keys(optionValuesByLabel).map(option => (
							<label className={styles.qtyLabel} key={option}>
								{option}: {optionValuesByLabel[option]}
							</label>
						))}
					</div>
					<div className={styles.cartButtonsWrapper}>
						<Button
							aria-label="Remove from Cart"
							className={styles.removeButton}
							disabled={loadingOrder}
							onClick={event => {
								removeLine.mutate(line.internalid);
								event.stopPropagation();
								event.preventDefault();
							}}
							size="icon"
							variant="ghost"
						>
							<IconTrash />
						</Button>
						<Button className={styles.addToWishlist} size="icon" variant="ghost">
							<IconClock />
						</Button>
					</div>
				</div>
			</div>
		</ItemLink>
	);
};

export default CartItem;
