import type { Category, Config } from '@/customTypes/environment';
import { customFetch } from '@/utils/network';
import { createStore, setter } from '@/utils/store';

export interface ConfigStore {
	categories: Category[];
	config: Config;
	loadingEnvironment: boolean;
	loadingEnvironmentShortCache: boolean;
	setConfig: (order: Partial<Config>) => void;
}

// TODO move to use react query
export default createStore<ConfigStore>((set, get) => {
	// @ts-expect-error fix this
	globalThis.SC.ENVIRONMENT = {};

	customFetch<string>({
		method: 'GET',
		text: true,
		url: '/public/shopping.environment.ssp',
	}).then(data => {
		// TODO unsafe as fuck
		eval(data);
		set({
			// @ts-expect-error fix this
			config: SC.CONFIGURATION,
			loadingEnvironment: false,
		});
	});
	customFetch<string>({
		method: 'GET',
		text: true,
		url: '/public/shopping.environment.shortcache.ssp',
	}).then(data => {
		eval(data);
		set({
			// @ts-expect-error fix this
			categories: SC.CATEGORIES,
			loadingEnvironmentShortCache: false,
		});
	});

	return {
		categories: [],
		config: {
			facets: [],
			facetsExtra: {
				exclude: [],
				masterFacets: [],
			},
			navigationData: [],
			sortOptions: [],
		},
		loadingEnvironment: true,
		loadingEnvironmentShortCache: true,
		setConfig: data => {
			set({
				config: setter(data, get().config),
			});
		},
	};
});
