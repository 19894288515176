import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CatchBoundary, createRootRoute, Outlet, ScrollRestoration } from '@tanstack/react-router';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import AddedToCartModal from '@/components/AddedToCartModal/AddedToCartModal.tsx';
import Breakpoint from '@/components/Breakpoint/Breakpoint.tsx';
import CookieConsent from '@/components/CookieConsent/CookieConsent.tsx';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Menu from '@/components/Menu/Menu.tsx';
import MiniCart from '@/components/MiniCart/MiniCart.tsx';
import NotFount from '@/components/NotFound/NotFount.tsx';
import { Toaster } from '@/components/Toast/Toaster.tsx';
import QueryProvider from '@/stores/QueryProvider.tsx';
import { getAssetUrl } from '@/utils/assets.ts';
import { initGTM } from '@/utils/GTM.ts';
import { useCMS } from '@/utils/useCMSHooks.ts';
import features from '@/vendor/features.ts';

const Root = () => {
	useCMS();
	useEffect(() => {
		initGTM(features.gtm.id);
	}, []);

	return (
		<CatchBoundary getResetKey={() => 'root'}>
			<QueryProvider>
				<HelmetProvider>
					<div style={{ minHeight: '100vh', width: '100%' }}>
						<Header />
						<CatchBoundary getResetKey={() => 'error'}>
							<Outlet />
						</CatchBoundary>
					</div>
					<Footer />
					<Helmet>
						<title>{features.vendorName}</title>
						<link href={getAssetUrl('favicon.ico')} rel="icon" type="image/svg+xml" />
					</Helmet>
					<AddedToCartModal />
				</HelmetProvider>
				<ReactQueryDevtools buttonPosition="bottom-right" />
				<ScrollRestoration />
				<Toaster />
				<CookieConsent />
				<Breakpoint maxWidthExclude={992}>
					<Menu />
				</Breakpoint>
				<MiniCart />
			</QueryProvider>
		</CatchBoundary>
	);
};

export const Route = createRootRoute({
	component: Root,
	notFoundComponent: NotFount,
});
