import * as ToastPrimitives from '@radix-ui/react-toast';
import { IconX } from '@tabler/icons-react';
import * as React from 'react';
import { createPortal } from 'react-dom';

import styles from './Toast.module.css';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Viewport>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...properties }, reference) =>
	createPortal(
		<ToastPrimitives.Viewport
			className={`${styles.viewport} ${className ?? ''}`}
			ref={reference}
			{...properties}
		/>,
		document.querySelector('#root-modal')!,
	),
);
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const Toast = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Root>,
	{
		variant?: 'default' | 'destructive';
	} & React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, variant = 'default', ...properties }, reference) => (
	<ToastPrimitives.Root
		className={`${styles.toast} ${styles[variant]} ${className ?? ''}`}
		ref={reference}
		{...properties}
	/>
));
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Action>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...properties }, reference) => (
	<ToastPrimitives.Action
		className={`${styles.action} ${className ?? ''}`}
		ref={reference}
		{...properties}
	/>
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Close>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...properties }, reference) => (
	<ToastPrimitives.Close
		className={`${styles.close} ${className ?? ''}`}
		ref={reference}
		toast-close=""
		{...properties}
	>
		<IconX className={styles.closeIcon} />
	</ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Title>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...properties }, reference) => (
	<ToastPrimitives.Title
		className={`${styles.title} ${className ?? ''}`}
		ref={reference}
		{...properties}
	/>
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Description>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...properties }, reference) => (
	<ToastPrimitives.Description
		className={`${styles.description} ${className ?? ''}`}
		ref={reference}
		{...properties}
	/>
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProperties = React.ComponentPropsWithoutRef<typeof Toast>;
type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
	Toast,
	ToastAction,
	type ToastActionElement,
	ToastClose,
	ToastDescription,
	type ToastProperties as ToastProps,
	ToastProvider,
	ToastTitle,
	ToastViewport,
};
