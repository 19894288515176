import { createFileRoute, Link } from '@tanstack/react-router';
import Autoplay from 'embla-carousel-autoplay';
import { Helmet } from 'react-helmet-async';

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/Carousel/Carousel.tsx';
import { CMSAreaHTML } from '@/components/CMSAreaHTML/CMSAreaHTML.tsx';
import CMSAreaItems from '@/components/CMSAreaItems/CMSAreaItems.tsx';
import features from '@/vendor/features.ts';

import styles from './Home.module.css';

const Home = () => (
	<main className={`${styles.container} containerGrid`}>
		<Carousel
			className={styles.carousel}
			opts={{ loop: true }}
			plugins={[
				Autoplay({
					delay: 5000,
					stopOnLastSnap: false,
				}),
			]}
		>
			<CarouselContent>
				{features.home.mainAssets.map((asset, index) => (
					<CarouselItem
						className={`home-carousel-item ${styles.carouselItem}`}
						data-index={index}
						key={index}
					>
						<Link
							dangerouslySetInnerHTML={{
								__html: asset.asset,
							}}
							params={asset.link.params}
							search={asset.link.search}
							to={asset.link.to}
						/>
					</CarouselItem>
				))}
			</CarouselContent>
			<CarouselPrevious />
			<CarouselNext />
		</Carousel>
		<div className={styles.secondaryContent}>
			{features.home.secondaryAssets.map((asset, index) => (
				<Link
					dangerouslySetInnerHTML={{
						__html: asset.asset,
					}}
					key={index}
					{...asset.link}
				/>
			))}
		</div>
		<h2>New Products</h2>
		<CMSAreaItems
			className={styles.itemCarousel}
			loadingAmount={10}
			name="home-new-products"
			pageType="home-page"
			path="/"
		/>
		<CMSAreaHTML name="home_cms_area_4" pageType="home-page" path="/" />
		<Helmet>
			<title>Home</title>
		</Helmet>
	</main>
);

export const Route = createFileRoute('/_home/')({
	component: Home,
});
