import { formatCurrency } from '../utils/functions';

import type { Features } from '../customTypes/features';
import type { ItemResponseItem, ItemResponseSimplifiedItem } from '../customTypes/itemResponse';

const features: Features = {
	addToCartModal: true,
	broadcastChannel: false,
	development: {
		enableMillion: false,
	},
	gtm: {
		id: 'GTM-TD9N67W5',
	},
	home: {
		mainAssets: [
			{
				asset: `<img src="https://scact.awalabs.site/awa-commerce/scact.awalabs.site/assets/banner-home-tablets-1.webp" alt="Tablets">`,
				link: {
					params: {
						_splat: 'tablets',
					},
					to: '/category/$',
				},
			},
			{
				asset: `<img src="https://scact.awalabs.site/awa-commerce/scact.awalabs.site/assets/banner-home-phones-1.webp" alt="Tablets">`,
				link: {
					params: {
						_splat: 'cell-phones',
					},
					to: '/category/$',
				},
			},
			{
				asset: `<img src="https://scact.awalabs.site/awa-commerce/scact.awalabs.site/assets/banner-home-tablets-2.webp" alt="Tablets">`,
				link: {
					params: {
						_splat: 'tablets',
					},
					to: '/category/$',
				},
			},
			{
				asset: `<img src="https://scact.awalabs.site/awa-commerce/scact.awalabs.site/assets/banner-home-phones-2.webp" alt="Tablets">`,
				link: {
					params: {
						_splat: 'cell-phones',
					},
					to: '/category/$',
				},
			},
		],
		secondaryAssets: [
			{
				asset:
					'<img src="https://latest-sca.awalabs.site/sca-dev-2023-2-0/extensions/AwaLabs/LatestDemo/23.1.3/img/banner-bottom-home-1.jpg" alt="home" />',
				link: {
					to: '/',
				},
			},
			{
				asset:
					'<img src="https://latest-sca.awalabs.site/sca-dev-2023-2-0/extensions/AwaLabs/LatestDemo/23.1.3/img/banner-bottom-home-2.jpg" alt="Cell phones" />',
				link: {
					params: {
						_splat: 'cell-phones',
					},
					to: '/category/$',
				},
			},
			{
				asset:
					'<img src="https://latest-sca.awalabs.site/sca-dev-2023-2-0/extensions/AwaLabs/LatestDemo/23.1.3/img/banner-bottom-home-3.jpg" alt="Tablets" />',
				link: {
					params: {
						_splat: 'tablets',
					},
					to: '/category/$',
				},
			},
		],
	},
	network: {
		accountID: 'TSTDRV1844288',
		menuBarPreload: 'viewport',
		preloadDelay: 200,
		preloadPDPImagesDelay: 500,
		preloadSearchImagesDelay: 200,
		proxyURL: 'latest-sca.awalabs.site',
		ssp: '/sca-dev-2023-2-0',
		uploadURL: 'scact.awalabs.site',
	},
	// TODO we may need to return all price levels and do the magic using a react hook to get the profile to know the price levels to use
	parseItem: (item: ItemResponseItem) => {
		const priceRangeSplit = item.onlinematrixpricerange?.split(' ') ?? [];
		const showSplitPrices = priceRangeSplit.length > 1 && priceRangeSplit[0] !== priceRangeSplit[1];
		const price = item.onlinecustomerprice ?? 0;
		// @ts-expect-error
		const comparePrice = item['pricelevel' + features.pricing.comparePriceLevel] as number;
		const result: ReturnType<typeof features.parseItem> = {
			categories: item.commercecategory?.categories.map(category => ({
				id: category.id,
				name: category.name,
				url: category.urls.join(''),
			})),
			description: item.storedetaileddescription,
			imagesDetail: item.itemimages_detail,
			inStock: item.isinstock,
			internalId: item.internalid,
			isBackorderable: item.isbackorderable,
			isMatrixParent: item.itemoptions_detail?.matrixtype === 'parent',
			itemId: item.itemid,
			name: item.displayname,
			options: item.itemoptions_detail?.fields,
			pageTitle: item.pagetitle,
			price,
			priceFormated: showSplitPrices
				? `${formatCurrency(Number(priceRangeSplit[0]))} to ${formatCurrency(Number(priceRangeSplit[1]))}`
				: formatCurrency(price),
			strikethroughPriceFormated:
				comparePrice && comparePrice > price && !showSplitPrices
					? formatCurrency(comparePrice)
					: undefined,
			type: item.itemtype,
			url: item.urlcomponent,
		};
		return result;
	},
	parseSimplifiedItem: (item: ItemResponseSimplifiedItem) => ({
		imagesDetail: item.itemimages_detail,
		internalId: item.internalid,
		itemId: item.itemid,
		name: item.displayname,
		priceFormated: item.onlinecustomerprice_detail?.onlinecustomerprice_formatted,
		url: item.urlcomponent,
	}),
	pdp: {
		autoPlayImageSlider: true,
		autoPlayItemsSlider: true,
		hiddenFacets: [
			'custcol_dfp_baseprice',
			'custcol_dfp_estshipdate',
			'custcol_revision_opt',
			'custcol13',
		],
	},
	pricing: {
		comparePriceLevel: 6, // MSRP price in awa account
	},
	pwaOptions: undefined,
	search: {
		closeFacetsOnChange: false,
		defaultSort: 'relevance:desc',
		infiniteScroll: true,
		maxVisibleSelectValues: 5,
		multiAddToCart: true,
		productsPerPage: 20,
		searchSpring: false,
		trueFacets: ['custitem_product_on_sale', 'custitem_product_new'], // TODO mover a config de ns
	},
	services: {
		builderIO: {
			apiKey: 'b5413c2ba9e74a0b84e2a6acfc884243',
		},
		// TODO mover a config de ns
		captcha: {
			siteKey: '6LcVhiApAAAAAA_qLZH_-LsqABnobMHuvj6PIAo0',
		},
	},
	tags: [
		{
			color: '#0077ff',
			field: 'product_on_sale',
			label: 'On Sale',
			position: 'right',
			shape: 'trapezoid',
		},
		{
			color: '#6531a2',
			field: 'product_new',
			label: 'New Arrival',
			position: 'left',
			shape: 'arrow',
		},
	],
	vendorName: 'AWA Commerce',
};

export default features;
