import { IconMenu2, IconX } from '@tabler/icons-react';
import React from 'react';

import { useStateStore } from '@/stores';

interface MenuToggleProperties {
	className?: string;
	id?: string;
}
// TODO add again animation that transitions between the two svgs
const MenuToggle: React.FC<MenuToggleProperties> = ({ className, id }) => {
	const open = useStateStore(store => store.state.menuOpen);
	const setState = useStateStore(store => store.setState);

	return (
		<button
			aria-label="Menu"
			className={className}
			id={id}
			onClick={() => setState({ menuOpen: !open })}
		>
			{open ? <IconX /> : <IconMenu2 />}
		</button>
	);
};

export default MenuToggle;
