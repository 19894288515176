import { customFetch } from '@/utils/network.ts';

interface Breadcrumb {
	canonical: string;
	categories: string[];
	fullurl: string;
	idpath: string;
	internalid: string;
	isprimaryurl: boolean;
	name: string;
	parenturl: string;
	sequencenumber: string;
}

interface Category {
	canonical: string;
	categories: string[];
	fullurl: string;
	idpath: string;
	internalid: string;
	isprimaryurl: boolean;
	metadescription: string;
	name: string;
	pagebannerurl: string;
	pagetitle: string;
	parenturl: string;
	sequencenumber: string;
	urlfragment: string;
}

interface Sibling {
	canonical: string;
	categories: string[];
	fullurl: string;
	idpath: string;
	internalid: string;
	isprimaryurl: boolean;
	name: string;
	parenturl: string;
	sequencenumber: string;
}

interface DataItem {
	_debug_requestTime: number;
	breadcrumb: Breadcrumb[];
	canonical: string;
	categories: Category[];
	fullurl: string;
	idpath: string;
	internalid: string;
	isprimaryurl: boolean;
	metadescription: string;
	name: string;
	pagebannerurl: string;
	pagetitle: string;
	parenturl: string;
	sequencenumber: string;
	siblings: Sibling[];
	urlfragment: string;
}

interface SearchType {
	[key: string]: number | string | undefined;

	p: number | undefined;
}

export const validateSearch = (search: Record<string, any>) => {
	const page = Number.parseInt(search.p, 10);
	const result: SearchType = {
		p: Number.isNaN(page) || page <= 1 ? undefined : page,
	};
	for (const key in search) {
		if (search[key] && key !== 'p') {
			result[key] = String(search[key]);
		}
	}

	return result;
};

export const fetchCategories = async (categoryURL: string) =>
	customFetch<{
		data: DataItem[];
	}>({
		dontAddSSP: true,
		parameters: {
			category_fields:
				'internalid,name,description,pagetitle,pageheading,pagebannerurl,addtohead,metakeywords,metadescription,displayinsite,urlfragment,idpath,fullurl,isprimaryurl',
			full_url: `/${categoryURL}`,
			site_id: '2',
			subcategory_fields:
				'internalid,name,description,pagetitle,pageheading,pagebannerurl,addtohead,metakeywords,metadescription,displayinsite,urlfragment,idpath,fullurl,isprimaryurl',
		},
		url: '/api/navigation/v1/categorynavitems',
	}).then(response => response.data);
