import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DependencyList, useCallback } from 'react';

import type { Config } from '@/customTypes/environment';
import { customFetch } from '@/utils/network';

export const orderQueryOptions: UseQueryOptions<Config, unknown, Config> = {
	queryFn: async () => {
		const data = await customFetch<string>({
			method: 'GET',
			text: true,
			url: '/public/shopping.environment.ssp',
		});
		eval(data);
		// @ts-expect-error unsafe af
		return SC.CONFIGURATION;
	},
	queryKey: ['config'],
};

// Fetches a slice of the current order and re-renders when the slice changes
// If your selector function depends on other variables, you can pass them as a second argument
export const useConfigQuery = <T>(
	selector: (order?: Config) => T,
	selectorDeps: DependencyList = [],
) => {
	const query = useQuery<Config, unknown, T>({
		...orderQueryOptions,
		notifyOnChangeProps: ['data'],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		select: useCallback(selector, selectorDeps),
	});
	return query.data as T;
};
