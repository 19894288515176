import {
	Toast,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
} from '@/components/Toast/Toast';
import { useToast } from '@/stores/ToastHooks.tsx';

export const Toaster = () => {
	const { toasts } = useToast();

	return (
		<ToastProvider>
			{toasts.map(({ action, description, id, title, ...properties }) => (
				<Toast key={id} {...properties}>
					<div>
						{title ? <ToastTitle>{title}</ToastTitle> : null}
						{description ? <ToastDescription>{description}</ToastDescription> : null}
					</div>
					{action}
					<ToastClose />
				</Toast>
			))}
			<ToastViewport />
		</ToastProvider>
	);
};
