import { IconLoader2 } from '@tabler/icons-react';
import Autoplay from 'embla-carousel-autoplay';
import React, { useMemo } from 'react';

import {
	Carousel,
	CarouselApi,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/Carousel/Carousel';
import Tags, { TagSizes } from '@/components/Tags/Tags.tsx';
import { Item } from '@/customTypes/Item';
import { getImageWithOptions } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

import styles from './ImageGallery.module.css';

// TODO make this work with simplified item
const ImageGallery: React.FC<{
	className?: string;
	item?: Item;
	options: Record<string, string | undefined>;
	tagSize?: TagSizes;
}> = ({ className, item, options, tagSize }) => {
	const images = useMemo(() => getImageWithOptions(item, options, 3), [item, options]);

	// State to hold the carousel API
	const [carouselApi, setCarouselApi] = React.useState<CarouselApi | null>(null);

	return (
		<div className={`${className ?? ''} ${styles.container}`}>
			{item ? (
				<Carousel
					opts={{ loop: true }}
					plugins={features.pdp.autoPlayImageSlider ? [Autoplay()] : []}
					setApi={setCarouselApi}
				>
					<CarouselContent>
						{images.map(url => (
							<CarouselItem key={url}>
								<img alt={item?.name ?? ''} className={styles.image} src={url} />
							</CarouselItem>
						))}
					</CarouselContent>
					<CarouselPrevious />
					<CarouselNext />
					<Tags item={item} size={tagSize} />
				</Carousel>
			) : (
				<IconLoader2 className={`${styles.loaderIcon} loader`} />
			)}
			<div className={styles.buttonContainer}>
				{images.length > 0 ? (
					images.map((image, index) => (
						<button
							className={styles.button}
							key={image}
							onClick={() => carouselApi?.scrollTo(index)} // Navigate to the corresponding slide
						>
							<img alt={item?.name} src={image} />
						</button>
					))
				) : (
					<IconLoader2 className={`${styles.loaderIconButton} loader`} />
				)}
			</div>
		</div>
	);
};

export default ImageGallery;
