import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import styles from './Slider.module.css';

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...properties }, reference) => (
	<SliderPrimitive.Root
		className={`${styles.sliderRoot} ${className ?? ''}`}
		ref={reference}
		{...properties}
	>
		<SliderPrimitive.Track className={styles.sliderTrack}>
			<SliderPrimitive.Range className={styles.sliderRange} />
		</SliderPrimitive.Track>

		<SliderPrimitive.Thumb className={styles.sliderThumb} />
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export default Slider;
