import { Item, RangeFacetType, SelectFacetType } from '@/customTypes/Item';
import { createStore } from '@/utils/store';

export type FacetType = RangeFacetType | SelectFacetType;

interface SearchCache {
	pdpSimplifiedItem?: Item;
	searchCache: Record<
		string,
		{
			facets: FacetType[];
			total: number;
		}
	>;
	setPdpSimplifiedItem: (item: Item) => void;
	setSearchCache: (
		searchKey: string,
		total: number,
		facets: (RangeFacetType | SelectFacetType)[],
	) => void;
}

export default createStore<SearchCache>((set, get) => ({
	searchCache: {},

	setPdpSimplifiedItem: item => {
		set({
			pdpSimplifiedItem: item,
		});
	},
	setSearchCache: (searchKey, total, facets) => {
		set({
			searchCache: {
				...get().searchCache,
				[searchKey]: {
					facets: facets ?? get().searchCache[searchKey]?.facets,
					total: total,
				},
			},
		});
	},
}));
