import { IconLoader2, IconLogin2, IconUserCircle } from '@tabler/icons-react';
import { Link, useRouter } from '@tanstack/react-router';
import React from 'react';

import { useLogOutMutation, useProfileLoading, useProfileQuery } from '@/stores/ProfileHooks.ts';

import styles from './HeaderLoginButton.module.css';

export const HeaderLoginButton: React.FC = () => {
	const isLoggedIn = useProfileQuery(profile => profile?.isLoggedIn === 'T');
	const loadingProfile = useProfileLoading();
	const logout = useLogOutMutation();
	const route = useRouter();

	if (isLoggedIn) {
		return (
			<>
				<button
					aria-label="Logout"
					className={styles.button}
					disabled={loadingProfile}
					onClick={event => {
						if (isLoggedIn && !loadingProfile) {
							event.preventDefault();
							logout.mutate();
						}
					}}
				>
					<IconLogin2 />
				</button>
				<Link
					aria-label="My Account"
					className={styles.button}
					disabled={loadingProfile}
					params={{}}
					search={{}}
					to="/account"
				>
					{loadingProfile ? <IconLoader2 className="loader" /> : <IconUserCircle />}
				</Link>
			</>
		);
	}

	return (
		<a
			aria-label="Login"
			className={styles.button}
			href="/login"
			onClick={event => {
				event.preventDefault();
				event.stopPropagation();
				if (route.state.location.href.startsWith('/login')) {
					return;
				}
				route.navigate({
					search: {
						to: route.state.location.href,
					},
					to: '/login',
				});
			}}
		>
			{loadingProfile ? <IconLoader2 className="loader" /> : <IconUserCircle />}
		</a>
	);
};

export default HeaderLoginButton;
