import {
	ImageUrl,
	Item,
	RangeFacetType,
	SelectFacetType,
	SimplifiedItem,
} from '@/customTypes/Item';
import {
	ItemRequestOptions,
	ItemResponseItem,
	ItemResponseSimplifiedItem,
} from '@/customTypes/itemResponse';
import { customFetch } from '@/utils/network';
import features from '@/vendor/features.ts';

export interface ItemResponse {
	facets?: (RangeFacetType | SelectFacetType)[];
	items: ItemResponseItem[];
	total: number;
}

export interface ParsedItemResponse {
	facets?: (RangeFacetType | SelectFacetType)[];
	items: Item[];
	total: number;
}

export const parseItem = (item: ItemResponseItem): Item => {
	const customFields = {};
	for (const key of Object.keys(item)) {
		if (key.startsWith('custitem_')) {
			// @ts-expect-error
			customFields[key.replace('custitem_', '')] = item[key];
		}
	}

	return {
		...features.parseItem(item),
		childItems: item.matrixchilditems_detail?.map(parseItem) ?? [],
		correlatedItemsDetail: item.correlateditems_detail?.map(parseItem),
		customFields,
		parent: item.matrix_parent ? parseItem(item.matrix_parent) : undefined,
	};
};

export const parseSimplifiedItem = (item: ItemResponseSimplifiedItem): SimplifiedItem => {
	const customFields = {};
	for (const key in item) {
		if (key.startsWith('custitem_')) {
			// @ts-expect-error
			customFields[key.replace('custitem_', '')] = item[key];
			// @ts-expect-error
			delete item[key];
		}
	}
	return {
		...features.parseSimplifiedItem(item),
		customFields,
	};
};

export const getItemsSS = async (options: object, signal?: AbortSignal): Promise<ItemResponse> =>
	customFetch({
		parameters: {
			c: '4902918_SB1',
			consultLevel: 0,
			country: 'US',
			custitem_is_nhc_item: true,
			custitem_nhc_hide_from_website: false,
			domain: 'nhc-sb.awalabs.site/',
			facet: {
				exclude: ['custitem_nhc_hide_from_website', 'custitem_is_nhc_item'],
			},
			isloggedin: false,
			language: 'en',
			model: 'itemColl',
			n: 3,
			postman: true,
			pricelevel: 9,
			priceLevelIds: {
				employee: 4,
				guest: {
					list: 8,
					selling: 7,
				},
				member: {
					list: 10,
					selling: 9,
				},
				retail: {
					list: 12,
					selling: 11,
				},
			},
			q: 'healthy',
			ss_sessionId: '6f19d2a1-cf7c-4e61-91bf-dc98be2a9e79',
			ss_siteId: 'th10wm',
			ss_userId: 'martin123@awalabs.com',
			use_pcv: 'T',
			...options,
		},
		signal,
		url: '/extensions/RenewCo%20-%2021-1%20-%20NHC/SearchSpring/1.0.0/services/search.Service.ss',
	});

/**
 * Fetch items from the SuiteCommerce item API.
 * @param options - The options to pass to the API
 * @param signal - An optional signal to abort the request
 * @returns A promise that resolves to the item response
 */
export const getItems = async (
	options: ItemRequestOptions,
	signal?: AbortSignal,
): Promise<ParsedItemResponse> =>
	customFetch<ItemResponse>({
		dontAddSSP: true,
		parameters: {
			...options,
			sort: options?.sort ?? features.search.defaultSort,
		},
		signal,
		url: '/api/items',
	}).then(response => ({
		...response,
		items: response.items.map(item => parseItem(item)),
	}));

/**
 * Get all images from an item, including the main image and any additional images.
 * @param item - The item to get the images from
 * @param resize - The resize id to use
 * @returns An array of image URLs
 */
export const getAllImages = (item?: Item, resize?: number) => {
	const images: string[] = [];
	try {
		for (const key of Object.keys(item?.imagesDetail ?? {})) {
			// @ts-expect-error we unsafely get the key
			const value = item?.imagesDetail[key];
			if (value.url) {
				images.push(value.url);
			}
			if (Array.isArray(value)) {
				for (const image of value) {
					if (image.url) {
						images.push(image.url);
					}
				}
			}
			if (value.urls) {
				for (const image of value.urls) {
					if (image.url) {
						images.push(image.url);
					}
				}
			}
		}
	} catch (error) {
		console.log(error);
		console.log(item);
		return [];
	}

	return images
		.filter((_, index) => images.indexOf(images[index]) === index)
		.map(url => url + (resize ? `?resizeid=${resize}` : ''));
};

export const getImageWithOptions = (
	item?: Item,
	options?: Record<string, string | undefined>,
	resize?: number,
): string[] => {
	const imageOptions = ['phone_color', 'internal_memory'];
	let current: any = item?.parent?.imagesDetail ?? item?.imagesDetail;
	for (const option of imageOptions) {
		if (options?.[option] && current?.[options[option]]) {
			current = current?.[options[option]];
		}
	}

	if (current?.url) {
		return [current.url + (resize ? `?resizeid=${resize}` : '')];
	}
	if (current?.urls) {
		return (current.urls as ImageUrl[]).map(url => url.url + (resize ? `?resizeid=${resize}` : ''));
	}
	return getAllImages(item?.parent ?? item, resize);
};
