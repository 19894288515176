import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import Conditional from '@/components/Conditional/Conditional.tsx';
import Stars from '@/components/Stars/Stars';
import { Item } from '@/customTypes/Item';
import { customFetch } from '@/utils/network.ts';

import styles from './Reviews.module.css';

interface Review {
	created_on: string;
	internalid: string;
	isVerified: boolean;
	itemid: string;
	not_useful_count: string;
	rating: string;
	rating_per_attribute: Record<string, any>; // Adjust type if specific structure is known
	text: string;
	title: string;
	useful_count: string;
	writer: {
		id: string;
		name: string;
	};
}

interface PaginatedReviews {
	page: number;
	records: Review[];
	recordsPerPage: number;
	totalRecordsFound: number;
}

const fetchReviews = (id: number) =>
	customFetch<PaginatedReviews>({
		url: `/services/ProductReviews.Service.ss?itemid=${id}&order=created_on:ASC`,
	});

// TODO pagination, sorting, filter by rate
const Reviews: React.FC<{
	item?: Item;
}> = ({ item }) => {
	const starRatings = useMemo(
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		() => JSON.parse(item?.customFields.ns_pr_rating_by_rate || '{}') as Record<string, number>,
		[item],
	);
	// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
	const totalReviews = Number(item?.customFields.ns_pr_count || 0);

	const averageRating = useMemo(
		() =>
			(totalReviews
				? Object.keys(starRatings).reduce(
						(accumulator, key) =>
							accumulator + starRatings[Number.parseInt(key)] * Number.parseInt(key),
						0,
					) / totalReviews
				: 0
			).toFixed(1),
		[totalReviews, starRatings],
	);

	const { data, isFetched } = useQuery({
		enabled: totalReviews > 0,
		queryFn: () => fetchReviews(item!.internalId),
		queryKey: ['reviews', item?.internalId],
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.leftContainer}>
					<div style={{ fontSize: '32px', fontWeight: 'bold' }}>
						{totalReviews > 0 ? averageRating : 'No Reviews Yet'}
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
						{Array.from({ length: 5 }).map((_, index) => (
							<span
								key={index}
								style={{ color: index < Math.round(Number(averageRating)) ? '#FFD700' : '#ddd' }}
							>
								★
							</span>
						))}
					</div>
					<div>{totalReviews} Reviews</div>
					<button className={styles.write}>Write a Review</button>
				</div>
				<div className={styles.rightContainer}>
					{Array.from({ length: 5 }).map((_, index) => (
						<div key={index} style={{ alignItems: 'center', display: 'flex', marginBottom: '4px' }}>
							<Stars className={styles.stars} value={index + 1} />
							<div className={styles.slider}>
								<span
									style={{
										width: `${totalReviews ? (Number(starRatings[index + 1] ?? 0) / totalReviews) * 100 : 0}%`,
									}}
								/>
							</div>
							<span style={{ marginLeft: '8px', width: '30px' }}>
								{starRatings[index + 1] ?? 0}
							</span>
						</div>
					))}
				</div>
			</div>
			<Conditional visible={totalReviews > 0}>
				<div className={styles.reviewsWrapper}>
					<h3>User Reviews</h3>
					{isFetched ? (
						<div className={styles.reviewsContainer}>
							{data?.records.map(review => (
								// TODO make a component
								<div className={styles.review} key={review.internalid}>
									<div className={styles.reviewHeader}>
										<Stars value={Number(review.rating)} />
										<span>{review.created_on.split(' ')[0]}</span>
									</div>
									<span className={styles.reviewWriter}>{review.writer.name}</span>
									<span className={styles.reviewTitle}>{review.title}</span>
									<span className={styles.reviewText}>{review.text}</span>
								</div>
							))}
						</div>
					) : (
						<span>Loading...</span>
					)}
				</div>
			</Conditional>
		</div>
	);
};

export default Reviews;
