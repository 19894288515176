'use client';

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';
import * as React from 'react';

import styles from './Carousel.module.css';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

export interface CarouselProperties {
	opts?: CarouselOptions;
	orientation?: 'horizontal' | 'vertical';
	plugins?: CarouselPlugin;
	setApi?: (api: CarouselApi) => void;
}

type CarouselContextProperties = {
	api: ReturnType<typeof useEmblaCarousel>[1];
	canScrollNext: boolean;
	canScrollPrev: boolean;
	carouselRef: ReturnType<typeof useEmblaCarousel>[0];
	scrollNext: () => void;
	scrollPrev: () => void;
} & CarouselProperties;

const CarouselContext = React.createContext<CarouselContextProperties | null>(null);

/**
 *
 */
const useCarousel = () => {
	const context = React.useContext(CarouselContext);

	if (!context) {
		throw new Error('useCarousel must be used within a <Carousel />');
	}

	return context;
};

const Carousel = React.forwardRef<
	HTMLDivElement,
	CarouselProperties & React.HTMLAttributes<HTMLDivElement>
>(
	(
		{ children, className, opts, orientation = 'horizontal', plugins, setApi, ...properties },
		reference,
	) => {
		const [carouselReference, api] = useEmblaCarousel(
			{
				...opts,
				axis: orientation === 'horizontal' ? 'x' : 'y',
			},
			plugins,
		);
		const [canScrollPrevious, setCanScrollPrevious] = React.useState(false);
		const [canScrollNext, setCanScrollNext] = React.useState(false);

		const onSelect = React.useCallback((api: CarouselApi) => {
			if (!api) {
				return;
			}

			setCanScrollPrevious(api.canScrollPrev());
			setCanScrollNext(api.canScrollNext());
		}, []);

		const scrollPrevious = React.useCallback(() => {
			api?.scrollPrev();
		}, [api]);

		const scrollNext = React.useCallback(() => {
			api?.scrollNext();
		}, [api]);

		const handleKeyDown = React.useCallback(
			(event: React.KeyboardEvent<HTMLDivElement>) => {
				if (event.key === 'ArrowLeft') {
					event.preventDefault();
					scrollPrevious();
				} else if (event.key === 'ArrowRight') {
					event.preventDefault();
					scrollNext();
				}
			},
			[scrollPrevious, scrollNext],
		);

		React.useEffect(() => {
			if (!api || !setApi) {
				return;
			}

			setApi(api);
		}, [api, setApi]);

		React.useEffect(() => {
			if (!api) {
				return;
			}

			onSelect(api);
			api.on('reInit', onSelect);
			api.on('select', onSelect);

			return () => {
				api?.off('select', onSelect);
			};
		}, [api, onSelect]);

		return (
			<CarouselContext.Provider
				value={{
					api: api,
					canScrollNext,
					canScrollPrev: canScrollPrevious,
					carouselRef: carouselReference,
					opts,
					orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
					scrollNext,
					scrollPrev: scrollPrevious,
				}}
			>
				<div
					aria-roledescription="carousel"
					className={`${className ?? ''} ${styles.carouselContainer}`}
					onKeyDownCapture={handleKeyDown}
					ref={reference}
					role="region"
					{...properties}
				>
					{children}
				</div>
			</CarouselContext.Provider>
		);
	},
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...properties }, reference) => {
		const { carouselRef, orientation } = useCarousel();

		return (
			<div className={styles.overflowHidden} ref={carouselRef}>
				<div
					className={`${className ?? ''} ${styles.carouselContent} ${orientation === 'horizontal' ? styles.horizontal : styles.vertical}`}
					ref={reference}
					{...properties}
				/>
			</div>
		);
	},
);
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...properties }, reference) => {
		const { orientation } = useCarousel();

		return (
			<div
				aria-roledescription="slide"
				className={`${className ?? ''} ${styles.carouselItem} ${orientation === 'horizontal' ? styles.itemHorizontal : styles.itemVertical}`}
				ref={reference}
				role="group"
				{...properties}
			/>
		);
	},
);
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<
	HTMLButtonElement,
	React.HTMLAttributes<HTMLButtonElement>
>(({ className, ...properties }, reference) => {
	const { canScrollPrev, orientation, scrollPrev } = useCarousel();

	return (
		<button
			className={`${className ?? ''} ${styles.carouselButton} ${orientation === 'horizontal' ? styles.carouselPrevButtonHorizontal : styles.carouselPrevButtonVertical}`}
			disabled={!canScrollPrev}
			onClick={scrollPrev}
			ref={reference}
			{...properties}
		>
			<IconChevronLeft className={styles.icon} />
			<span className="sr-only">Previous slide</span>
		</button>
	);
});
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(
	({ className, ...properties }, reference) => {
		const { canScrollNext, orientation, scrollNext } = useCarousel();

		return (
			<button
				className={`${className ?? ''} ${styles.carouselButton} ${orientation === 'horizontal' ? styles.carouselNextButtonHorizontal : styles.carouselNextButtonVertical}`}
				disabled={!canScrollNext}
				onClick={scrollNext}
				ref={reference}
				{...properties}
			>
				<IconChevronRight className={styles.icon} />
				<span className="sr-only">Next slide</span>
			</button>
		);
	},
);
CarouselNext.displayName = 'CarouselNext';

export {
	Carousel,
	type CarouselApi,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
};
