import { IconCheck, IconX } from '@tabler/icons-react';
import React, { useCallback, useMemo } from 'react';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/Accordion/Accordion';
import Conditional from '@/components/Conditional/Conditional.tsx';
import SearchLink from '@/components/SearchLink/SearchLink.tsx';
import { FacetItem } from '@/customTypes/environment';
import { formatFacetUrl } from '@/utils/functions.ts';
import features from '@/vendor/features.ts';

import styles from './SelectFacet.module.css';

interface SelectFacetProperties {
	className?: string;
	config?: FacetItem;
	from: '/category/$' | '/search';
	onChange?: () => void;
	params?: Record<string, any>;
	search: Record<string, string | undefined>;
	url: string;
	values: {
		label: string;
		url: string;
	}[];
}

const SelectFacet: React.FC<SelectFacetProperties> = ({
	className,
	config,
	from,
	onChange,
	params,
	search,
	url,
	values,
}) => {
	const selectedValue = search[url];
	const selectedValues = useMemo(
		() => (typeof selectedValue === 'string' ? selectedValue.split(',') : []),
		[selectedValue],
	);
	const prettyName = useMemo(() => config?.name ?? formatFacetUrl(url), [config, url]);
	const sortedValues = useMemo(
		() =>
			values.sort((a, b) =>
				selectedValues.includes(a.url) ? (selectedValues.includes(b.url) ? 0 : -1) : 1,
			),
		[selectedValues, values],
	);

	const getLink = useCallback(
		// eslint-disable-next-line react/no-unused-prop-types
		({ label, url: valueURL }: { label: string; url: string }) => (
			<SearchLink
				className={styles.option}
				currentSearch={search}
				from={from}
				key={valueURL}
				onClick={onChange}
				params={params}
				search={search => {
					if (selectedValues.includes(valueURL)) {
						return {
							...search,
							p: undefined,
							[url]: selectedValues.filter(value => value !== valueURL).join(',') || undefined,
						};
					}
					if (search[url]) {
						return { ...search, p: undefined, [url]: search[url] + ',' + valueURL };
					}
					return { ...search, p: undefined, [url]: valueURL };
				}}
				to={from}
			>
				{selectedValues.includes(valueURL) ? (
					<IconCheck className={styles.check} />
				) : (
					<span className={styles.check} />
				)}
				{label}
			</SearchLink>
		),
		[from, onChange, params, search, selectedValues, url],
	);

	const accordionContent = useMemo(
		() => sortedValues.slice(features.search.maxVisibleSelectValues).map(value => getLink(value)),
		[getLink, sortedValues],
	);

	const visibleContent = useMemo(
		() =>
			sortedValues.slice(0, features.search.maxVisibleSelectValues).map(value => getLink(value)),
		[getLink, sortedValues],
	);

	if (values.length === 0 && selectedValues.length === 0) {
		return null;
	}

	return (
		<Accordion
			className={`${styles.container} ${className ?? ''}`}
			collapsible={true}
			defaultValue={config?.name ?? url}
			type="single"
		>
			<AccordionItem title={prettyName} value="description">
				<AccordionTrigger
					className={styles.header}
					disabled={accordionContent.length <= features.search.maxVisibleSelectValues}
				>
					<span>{prettyName}</span>
					<Conditional visible={selectedValues.length > 0}>
						<SearchLink
							aria-label={`Clear ${prettyName}`}
							className={styles.clearButton}
							currentSearch={search}
							onClick={event => {
								event.stopPropagation();
								onChange?.();
							}}
							params={{}}
							search={search =>
								({
									...search,
									[url]: undefined,
								}) as any
							}
							to={from}
						>
							<IconX />
						</SearchLink>
					</Conditional>
				</AccordionTrigger>
				{visibleContent}
				{accordionContent.length > features.search.maxVisibleSelectValues ? (
					<AccordionContent>{accordionContent}</AccordionContent>
				) : null}
			</AccordionItem>
		</Accordion>
	);
};

export default SelectFacet;
