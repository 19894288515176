import React from 'react';

import CustomLink, { CustomLinkProperties } from '@/components/CustomLink/CustomLink.tsx';
import { Item } from '@/customTypes/Item';
import { preloadImage } from '@/utils/assets.ts';
import { getAllImages } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

export const ItemLink: React.FC<
	{ item?: Item } & Omit<CustomLinkProperties, 'from' | 'params' | 'to'>
> = ({ item, ...properties }) => (
	<CustomLink
		{...properties}
		params={{ itemURL: item?.url }}
		secondaryDelay={features.network.preloadPDPImagesDelay}
		secondaryFetch={async () => {
			if (!item) return;
			const start = performance.now();
			const images = getAllImages(item, 3);
			await Promise.all(images.map(url => preloadImage(url)));
			console.log(
				`successfully preloaded ${images.length} PDP images for: ${item?.url} in ${Math.floor(performance.now() - start)}`,
			);
		}}
		to="/item/$itemURL"
	/>
);

export default ItemLink;
