import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import Button from '@/components/Button/Button.tsx';

import styles from './CookieConsent.module.css';

export const CookieConsent: React.FC = () => {
	const [accepted, setAccepted] = useState(localStorage.getItem('CookieConsentAccepted') === 'true');
	if (accepted) return;

	return createPortal(
		<div className={styles.wrapper}>
			<div className={`${styles.container} containerGrid`}>
				<p>This site uses cookies, no seas mamahuevo y aceptalas</p>
				<div>
					<Button
						className={styles.acceptButton}
						onClick={() => {
							setAccepted(true);
							localStorage.setItem('CookieConsentAccepted', 'true');
						}}
					>
						Accept Cookies
					</Button>
					<Button onClick={() => alert('tremendo mamahuevo')} variant="secondary">
						Decline
					</Button>
				</div>
			</div>
		</div>,
		document.querySelector('#root-modal')!,
	);
};

export default CookieConsent;
