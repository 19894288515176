import React from 'react';

import CMSAreaHTML from '@/components/CMSAreaHTML/CMSAreaHTML.tsx';

import styles from './Footer.module.css';

export const Footer: React.FC = () => (
	<footer className={styles.footer}>
		<span>AwaLabs 2024 ®</span>
		<CMSAreaHTML name="global_banner_footer1" pageType="home-page" path="/" />
	</footer>
);

export default Footer;
