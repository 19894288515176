import { Link } from '@tanstack/react-router';
import React, { useMemo } from 'react';

import MenuElement from '@/components/MenuElement/MenuElement';
import { useConfigStore } from '@/stores';
import features from '@/vendor/features.ts';

import styles from './MenuBar.module.css';
import menuElementStyles from '../MenuElement/MenuElement.module.css';

export const MenuBar: React.FC = () => {
	const categories = useConfigStore(store => store.categories);
	const navigationData = useConfigStore(store => store.config.navigationData);
	const menu = useMemo(
		() => [
			...categories.map(item => <MenuElement item={item} key={item.internalid} />),
			...navigationData
				.filter(item => item.level === '1' && item.href)
				.map((item, index) => (
					<li className={menuElementStyles.parent} key={index}>
						<Link
							className={menuElementStyles.link}
							preload={features.network.menuBarPreload}
							to={item.href}
						>
							{item.text}
						</Link>
					</li>
				)),
		],
		[categories, navigationData],
	);

	return (
		<div className={styles.wrapper}>
			<ul className={`${styles.container} containerGrid`}>{menu}</ul>
		</div>
	);
};

export default MenuBar;
