import { IconX } from '@tabler/icons-react';
import { Link, useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';

import Conditional from '@/components/Conditional/Conditional';
import Slider from '@/components/Slider/Slider';
import { useConfigQuery } from '@/stores/ConfigHooks.ts';

import styles from './NumberFacet.module.css';

const NumberFacet: React.FC<{
	className?: string;
	from: '/category/$' | '/search';
	max: number;
	min: number;
	name: string;
	search: Record<string, string | undefined>;
	url: string;
}> = ({ className, from, max, min, name, search, url }) => {
	const navigate = useNavigate();
	const currentMin = search[`${name}.from`];
	const currentMax = search[`${name}.to`];
	const facetsConfig = useConfigQuery(config => config?.facets);
	const facetInfo = useMemo(
		() => facetsConfig?.find(facet => facet.id === url),
		[facetsConfig, url],
	);

	if (!facetInfo) {
		return null;
	}
	return (
		<div className={`${styles.container} ${className ?? ''}`}>
			<div className={styles.topContainer}>
				<label>{facetInfo?.name}</label>
				<Conditional visible={currentMin ?? currentMax}>
					<Link
						from={from}
						params={{}}
						search={search => ({
							...search,
							[`${name}.from`]: undefined,
							[`${name}.to`]: undefined,
							p: undefined,
						})}
						to={from}
					>
						<IconX />
					</Link>
				</Conditional>
			</div>
			<div className={styles.rangeContainer}>
				<Slider
					defaultValue={[min, max]}
					max={max}
					min={min}
					onValueCommit={([min, max]) => {
						navigate({
							from,
							search: previous => ({
								...previous,
								[`${name}.from`]: min,
								[`${name}.to`]: max,
								p: undefined,
							}),
							to: from,
						});
					}}
				/>
			</div>
		</div>
	);
};

export default NumberFacet;
