import React from 'react';
import ReactDOM from 'react-dom';

import Button from '@/components/Button/Button.tsx';
import { Item } from '@/customTypes/Item';
import { useStateStore } from '@/stores';
import { useAddLinesMutation } from '@/stores/OrderHooks.ts';
import { formatCurrency } from '@/utils/functions.ts';
import features from '@/vendor/features.ts';

import styles from './MultiAddToCart.module.css';

export const MultiAddToCart: React.FC<{
	afterAdd?: () => void;
	quantities: Map<Item, number>;
}> = ({ afterAdd, quantities }) => {
	const setState = useStateStore(store => store.setState);
	const addLinesMutation = useAddLinesMutation();
	if (!features.search.multiAddToCart) {
		return null;
	}
	const totalQuantity = quantities
		.values()
		.reduce((accumulator, current) => accumulator + current, 0);
	if (totalQuantity === 0) {
		return null;
	}
	return ReactDOM.createPortal(
		<div className={styles.wrapper}>
			<div className={`${styles.container} containerGrid`}>
				<div>
					<label>Quantity: {totalQuantity}</label>
					<span className={styles.separator}>|</span>
					<label>
						Subtotal:{' '}
						{formatCurrency(
							quantities
								.entries()
								.reduce(
									(accumulator, [currentItem, currentQuantity]) =>
										accumulator + currentItem.price * currentQuantity!,
									0,
								),
						)}
					</label>
				</div>
				<Button
					onClick={() => {
						setState({
							latestAdditions: [...quantities.entries()].map(([item, quantity]) => ({
								addedItem: item,
								item: item,
								options: {},
								optionValues: {},
								quantity,
							})),
						});
						afterAdd?.();
						addLinesMutation.mutateAsync(
							[...quantities.entries()].map(([item, quantity]) => ({
								item: {
									internalid: item.internalId,
									itemtype: item.type,
								},
								quantity,
							})),
						);
					}}
				>
					Add to Cart
				</Button>
			</div>
		</div>,
		document.querySelector('#root-modal')!,
	);
};

export default MultiAddToCart;
