import { IconLogin2, IconUserCircle } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

import { useConfigStore, useStateStore } from '@/stores';
import { useProfileLoading, useProfileQuery } from '@/stores/ProfileHooks.ts';

import styles from './Menu.module.css';
import MenuElement from '../MenuElement/MenuElement.tsx';

const Menu: React.FC = () => {
	const categories = useConfigStore(store => store.categories);
	const containerReference = useRef<HTMLDivElement>(null);
	const open = useStateStore(store => store.state.menuOpen);
	const setState = useStateStore(store => store.setState);
	const loggedIn = useProfileQuery(profile => profile?.isLoggedIn === 'T');
	const loading = useProfileLoading();

	useEffect(() => {
		const listener = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			if (
				!containerReference.current?.contains(target) &&
				!document.querySelector('#menu-button')?.contains(target)
			) {
				setState({ menuOpen: false });
			}
		};
		document.addEventListener('click', listener, true);

		return () => {
			document.removeEventListener('click', listener, true);
		};
	}, [setState]);

	const elements = useMemo(
		() => categories.map(item => <MenuElement item={item} key={item.internalid} />),
		[categories],
	);

	return ReactDOM.createPortal(
		<RemoveScroll enabled={open}>
			<div className={`${styles.wrapper} ${open ? styles.open : ''}`}>
				<menu className={styles.container} ref={containerReference}>
					<Link
						className={styles.account}
						disabled={loading}
						onClick={() => setState({ menuOpen: false })}
						params={{}}
						search={{}}
						to={loggedIn ? '/account' : '/login'}
					>
						{loggedIn ? <IconUserCircle /> : <IconLogin2 />}
						{loggedIn ? 'My Account' : 'Login/Register'}
					</Link>
					{elements}
				</menu>
			</div>
		</RemoveScroll>,
		document.querySelector('#root-modal')!,
	);
};

export default Menu;
