import { Link, LinkProps } from '@tanstack/react-router';
import React, { useEffect } from 'react';

export type CustomLinkProperties = {
	secondaryDelay?: number;
	secondaryFetch?: () => void;
} & LinkProps &
	React.HTMLAttributes<HTMLAnchorElement>;

export const CustomLink: React.FC<CustomLinkProperties> = ({
	disabled,
	onMouseEnter,
	onMouseLeave,
	secondaryDelay,
	secondaryFetch,
	...properties
}) => {
	const [mouseInside, setMouseInside] = React.useState(false);

	useEffect(() => {
		if (mouseInside && secondaryFetch && secondaryDelay && !disabled) {
			const timeout = setTimeout(() => {
				secondaryFetch();
				setMouseInside(false);
			}, secondaryDelay);
			return () => clearTimeout(timeout);
		}
	}, [disabled, mouseInside, secondaryDelay, secondaryFetch]);

	return (
		<Link
			{...properties}
			disabled={disabled}
			onMouseEnter={event => {
				setMouseInside(true);
				onMouseEnter?.(event);
			}}
			onMouseLeave={event => {
				setMouseInside(false);
				onMouseLeave?.(event);
			}}
		/>
	);
};

export default CustomLink;
