import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { customFetch } from '@/utils/network.ts';

interface Content {
	areaName: string;
	contentTemplate: string;
	contentTypeId: number;
	contentTypeName: string;
	description: null | string;
	endDateTime: null | string;
	fields: {
		custrecord_html_clob_html: string;
		custrecord_merch_rule_url: string;
	};
	global: string;
	id: number;
	isDraft: boolean;
	name: string;
	pageType: null | string;
	path: null | string;
	sequence: number;
	siteId: number;
	startDateTime: string;
	tags: any[];
	translationEnabled: boolean;
	translations: Record<string, any>;
}

export const useCMSQuery = (path: string, pageType: string, contentType: string, name: string) => {
	const { data } = useQuery({
		queryFn: () =>
			customFetch<{ data: Content[] }>({
				dontAddSSP: true,
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
				},
				parameters: {
					locale: 'en_US',
					pageType,
					path,
				},
				url: '/api/cms/v2/contents',
			}).then(response => response.data),
		queryKey: ['CMS', pageType, path],
	});
	return useMemo(
		() =>
			data?.filter(item => item.contentTypeName === contentType && item.areaName === name) ?? [],
		[contentType, data, name],
	);
};
