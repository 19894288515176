import { useQuery } from '@tanstack/react-query';
import React from 'react';

import Conditional from '@/components/Conditional/Conditional';
import ItemCarousel from '@/components/ItemCarousel/ItemCarousel';
import { Item } from '@/customTypes/Item';
import { ItemResponse, parseItem } from '@/utils/Item.ts';
import { customFetch } from '@/utils/network.ts';

import styles from './RelatedItems.module.css';

const getRelatedItems = async (id: number): Promise<Item[]> =>
	customFetch<ItemResponse>({
		dontAddSSP: true,
		parameters: {
			fieldset: 'correlateditems_details',
			id,
			limit: 10,
			offset: 0,
		},
		url: '/api/items',
	}).then(response =>
		response.items.flatMap(item => item.correlateditems_detail?.map(item => parseItem(item)) ?? []),
	);

export const RelatedItems: React.FC<{ internalId?: number }> = ({ internalId }) => {
	const { data } = useQuery({
		enabled: Boolean(internalId),
		queryFn: () => getRelatedItems(internalId!),
		queryKey: ['related-item', internalId],
	});

	return (
		<Conditional visible={data?.length}>
			<div className={styles.container}>
				<h3>Related Items</h3>
				<ItemCarousel items={data ?? []} loadingAmount={10} />
			</div>
		</Conditional>
	);
};

export default RelatedItems;
