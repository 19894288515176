import React from 'react';

import Conditional from '@/components/Conditional/Conditional.tsx';
import { Item } from '@/customTypes/Item';

import styles from './Price.module.css';

export const Price: React.FC<{ item?: Item; size: 'large' | 'small' }> = ({ item, size }) => (
	<div className={`${styles.container} ${styles[size]}`}>
		<span className={styles.price}>{item?.priceFormated ?? '$__.__'}</span>
		<Conditional visible={item?.strikethroughPriceFormated !== undefined}>
			<span className={styles.strikethroughPrice}>{item?.strikethroughPriceFormated}</span>
		</Conditional>
	</div>
);

export default Price;
