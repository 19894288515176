import { createFileRoute } from '@tanstack/react-router';
import { Helmet } from 'react-helmet-async';

import CartItem from '@/components/CartItem/CartItem';
import GoToCheckoutButton from '@/components/GoToCheckoutButton/GoToCheckoutButton.tsx';
import OrderSummary from '@/components/OrderSummary/OrderSummary';
import { useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './Cart.module.css';

const Cart = () => {
	const lines = useOrderQuery(order => order?.lines) ?? [];
	const summary = useOrderQuery(order => order?.summary);
	return (
		<main className={`${styles.cartWrapper} containerGrid`}>
			<div className={styles.linesContainer}>
				{lines.map(line => (
					<CartItem key={line.internalid} line={line} />
				))}
			</div>
			<div className={styles.summaryContainer}>
				<OrderSummary summary={summary} />
				<GoToCheckoutButton />
			</div>
			<Helmet>
				<title>Cart</title>
			</Helmet>
		</main>
	);
};

export const Route = createFileRoute('/cart/_cart/')({
	component: Cart,
});
