import { useQuery } from '@tanstack/react-query';
import React from 'react';

import ItemCarousel, { ItemCarouselProperties } from '@/components/ItemCarousel/ItemCarousel.tsx';
import { useCMSQuery } from '@/stores/CMSHooks.ts';
import { ItemResponse, parseItem } from '@/utils/Item.ts';
import { customFetch } from '@/utils/network.ts';

type CMSAreaHTMLProperties = {
	name: string;
	pageType: string;
	path: string;
} & ItemCarouselProperties;

export const CMSAreaItems: React.FC<CMSAreaHTMLProperties> = ({
	name,
	pageType,
	path,
	...properties
}) => {
	const area = useCMSQuery(path, pageType, 'CMS_MERCHZONE', name)[0];
	const { data } = useQuery({
		enabled: Boolean(area),
		queryFn: () =>
			customFetch<ItemResponse>({
				dontAddSSP: true,
				url: decodeURIComponent(area?.fields.custrecord_merch_rule_url),
			}).then(response => response.items.map(item => parseItem(item))),
		queryKey: ['CMS_MERCHZONE', area?.fields.custrecord_merch_rule_url],
	});
	return <ItemCarousel data-cms-area={name} items={data} {...properties} />;
};

export default CMSAreaItems;
