import React, { useMemo } from 'react';

import SearchLink from '@/components/SearchLink/SearchLink.tsx';
import Switch from '@/components/Switch/Switch';
import { FacetItem } from '@/customTypes/environment';
import { formatFacetUrl } from '@/utils/functions.ts';

import styles from './BooleanFacet.module.css';

interface BooleanFacetProperties {
	className?: string;
	config?: FacetItem;
	from: '/category/$' | '/search';
	onChange?: () => void;
	params?: Record<string, any>;
	search: Record<string, string | undefined>;
	url: string;
	values: { url: string }[];
}

const BooleanFacet: React.FC<BooleanFacetProperties> = ({
	className,
	config,
	from,
	onChange,
	params,
	search,
	url,
	values,
}) => {
	const selectedValue = !!search[url];
	const prettyName = useMemo(() => config?.name ?? formatFacetUrl(url), [config, url]);
	if (!values.some(value => value.url === (!selectedValue).toString())) {
		return null;
	}

	return (
		<SearchLink
			className={`${styles.option} ${className ?? ''}`}
			currentSearch={search}
			from={from}
			onClick={onChange}
			params={params}
			search={search => ({
				...search,
				p: undefined,
				[url]: selectedValue ? undefined : 'true',
			})}
			to={from}
		>
			<span>{prettyName}</span>
			<Switch aria-label={prettyName} checked={selectedValue} />
		</SearchLink>
	);
};

export default BooleanFacet;
