import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DependencyList, useCallback, useMemo } from 'react';

import { Item } from '@/customTypes/Item';
import { useProfileQuery } from '@/stores/ProfileHooks.ts';
import { parseItem } from '@/utils/Item.ts';
import { customFetch } from '@/utils/network';
import features from '@/vendor/features.ts';

interface Owner {
	id: string;
	name: string;
}

interface LineDetailsItem {
	created: string;
	createddate: string;
	description: string;
	internalid: string;
	item: Item;
	lastmodified: string;
	quantity: number;
}

interface List {
	created: string;
	description: string;
	internalid: string;
	items: LineDetailsItem[];
	lastmodified: string;
	lastmodifieddate: string;
	name: string;
	owner: Owner;
	scopeId: string;
	scopeName: string;
	templateId: string;
	typeId: string;
	typeName: string;
}

export const wishListsQueryOptions: UseQueryOptions<List[], unknown, List> = {
	queryFn: () =>
		customFetch<List[]>({
			method: 'GET',
			url: '/services/ProductList.Service.ss',
		}).then(response =>
			response.map(list => ({
				...list,
				items: list.items.map(item => ({
					...item,
					item: parseItem(item.item as any),
				})),
			})),
		),
	queryKey: ['wishlists'],
};

// Fetches a slice of the current order and re-renders when the slice changes
// If your selector function depends on other variables, you can pass them as a second argument
export const useWishListsQuery = <T>(
	selector: (wishlists?: List[]) => T,
	selectorDeps: DependencyList = [],
) => {
	const loggedIn = useProfileQuery(profile => profile?.isLoggedIn === 'T');
	const query = useQuery<List[], unknown, T>({
		...wishListsQueryOptions,
		enabled: loggedIn,
		notifyOnChangeProps: ['data'],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		select: useCallback(selector, selectorDeps),
	});
	return query.data as T;
};

export const useFavoritesQuery = () => {
	const lists = useWishListsQuery(lists => lists);
	return useMemo(() => lists?.find(list => list.name === 'Favorites'), [lists]);
};

interface AddToWishlistParameters {
	item: Item;
	listID: string;
	quantity: number;
	userID: string;
}

// TODO optimistic updates
export const useAddToWishlistMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<LineDetailsItem, unknown, AddToWishlistParameters>({
		mutationFn: async ({ item, listID, quantity, userID }) =>
			customFetch<{ errorMessage?: string } & LineDetailsItem>({
				body: {
					item: {
						internalid: item.internalId,
						type: 'InvtPart', // Added based on request structure
					},
					location,
					productList: {
						id: listID,
						owner: userID,
					},
					quantity,
				},
				method: 'POST',
				parameters: {
					c: features.network.accountID,
					user: userID,
				},
				url: '/services/ProductList.Item.Service.ss',
			}).then(response => {
				if (response.errorMessage) {
					throw response.errorMessage;
				}
				return response;
			}),
		onSuccess: (item, variables) => {
			// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
			const currentData = queryClient.getQueryData(['wishlist', variables.listID]) as List;

			// TODO update wishlists query
			queryClient.setQueryData(['wishlist', variables.listID], {
				...currentData,
				items: [
					...currentData.items,
					{
						...item,
						item: variables.item,
					},
				],
			});
		},
	});
};

export const useRemoveFromWishlistMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<unknown, unknown, { internalid: string; listID: string; user: string }>({
		mutationFn: async ({ internalid, user }) =>
			customFetch<{ errorMessage?: string; ok: boolean }>({
				method: 'DELETE',
				parameters: {
					internalid,
					user,
				},
				url: '/services/ProductList.Item.Service.ss',
			}),
		onSuccess: (_, data) => {
			// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
			const currentData = queryClient.getQueryData(['wishlist', data.listID]) as List;

			// TODO update wisthlists query
			// Invalidate specific wishlist query
			queryClient.setQueryData(['wishlist', data.listID], {
				...currentData,
				items: currentData.items.filter(item => item.internalid !== data.internalid),
			});
		},
	});
};
