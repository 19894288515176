import { Item } from '@/customTypes/Item';
import { getItems } from '@/utils/Item.ts';

/**
 * Fetches item information based on the provided item URL.
 * @param itemURL - The URL of the item to fetch.
 * @returns A promise that resolves to the item information.
 */
export const getItemInfo = async (itemURL: string): Promise<Item> => {
	const search = await getItems({
		fieldset: 'awa_commerce_details',
		limit: 1,
		offset: 0,
		url: itemURL,
	}).catch(error => {
		console.log(error);
		throw error;
	});

	if (search.items.length > 0) {
		return search.items[0];
	}
	throw new Error('No results found');
};
