import { IconAdjustments } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link, LinkProps } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import Facets from '@/components/Facets/Facets';
import PaginatedSearch from '@/components/PaginatedSearch/PaginatedSearch';
import SelectedFacets from '@/components/SelectedFacets/SelectedFacets.tsx';
import { queryClient, useConfigStore } from '@/stores';
import { fetchCategories, validateSearch } from '@/utils/category.ts';
import { getSearchKey } from '@/utils/functions.ts';
import { getItems } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

import styles from './Category.module.css';

const CategoryPage = () => {
	const [openFacets, setOpenFacets] = useState(false);
	const { _splat: categoryURL } = Route.useParams();
	const categories = useConfigStore(store => store.categories);
	const categoryInfo = useMemo(
		() => categories.find(item => item.fullurl === `/${categoryURL}`),
		[categories, categoryURL],
	);
	// TODO move this to a child component that has the infinite or regular scroll and the facets
	// That way it doesn't re-render the whole page
	const search = Route.useSearch();
	const parsedSearch = {
		...search,
		commercecategoryurl: '/' + categoryURL,
	};
	const { data } = useQuery({
		enabled: Boolean(categoryURL) && false,
		queryFn: () => fetchCategories(categoryURL!),
		queryKey: ['category-categories', categoryURL],
	});

	const { data: data2 } = useQuery({
		enabled: Boolean(categoryURL),
		queryFn: () =>
			getItems({
				...parsedSearch,
				fieldset: 'awa_commerce_search',
				include: 'facets',
				limit: features.search.productsPerPage,
				offset: ((parsedSearch.p ?? 1) - 1) * features.search.productsPerPage,
			}),
		queryKey: ['search', ...getSearchKey(parsedSearch)],
	});

	const requestInfo = data?.[0];

	const links: LinkProps[] = requestInfo?.breadcrumb.map(item => ({
		children: item.name,
		params: { _splat: item.fullurl },
		to: '/category/$',
	})) ?? [
		{
			children: categoryInfo?.name ?? 'Loading...',
			disabled: !categoryInfo,
			params: { _splat: categoryURL },
			to: '/category/$',
		},
	];

	return (
		<>
			<main className={`${styles.main} containerGrid`}>
				<Breadcrumb links={[{ children: 'Home', to: '/' }, ...links]} />
				<SelectedFacets
					facets={data2?.facets ?? []}
					from="/category/$"
					params={{
						_splat: categoryURL,
					}}
					search={search}
				/>
				<div className={styles.topContainer}>
					<img
						alt={requestInfo?.pagetitle}
						src={`http://localhost:8081${requestInfo?.pagebannerurl}`}
					/>
					<span>{requestInfo?.pagetitle ?? categoryInfo?.name ?? categoryURL}</span>
				</div>
				<div className={styles.gridContainer}>
					{requestInfo?.categories.map(category => (
						<Link
							className={styles.category}
							key={category.internalid}
							params={{ _splat: category.fullurl }}
							search={{
								p: undefined,
							}}
							to="/category/$"
						>
							<h2>{category.name}</h2>
							<img alt={category.name} src={`http://localhost:8081${category.pagebannerurl}`} />
						</Link>
					))}
				</div>
				<PaginatedSearch
					from="/category/$"
					items={data2?.items}
					page={search.p ?? 1}
					pageSize={features.search.productsPerPage}
					search={search}
					total={data2?.total}
				/>
				<button className={styles.openFacetsButton} onClick={() => setOpenFacets(open => !open)}>
					<IconAdjustments />
					Filter & Sort
				</button>
			</main>

			<Facets
				facets={data2?.facets ?? []}
				from="/category/$"
				open={openFacets}
				params={{ _splat: categoryURL }}
				setOpenFacets={setOpenFacets}
				sort={search.sort as string}
			/>
			<Helmet>
				<title>{requestInfo?.pagetitle ?? categoryInfo?.name ?? categoryURL}</title>
			</Helmet>
		</>
	);
};

export const loader = ({ location: { search }, params: { _splat: categoryURL } }: any) => {
	const parsedSearch = {
		...validateSearch(search),
		commercecategoryurl: '/' + categoryURL,
	};
	return queryClient.fetchQuery({
		queryFn: () =>
			getItems({
				...parsedSearch,
				fieldset: 'awa_commerce_search',
				include: 'facets',
				limit: features.search.productsPerPage,
				offset: ((parsedSearch.p ?? 1) - 1) * features.search.productsPerPage,
			}),
		queryKey: ['search', ...getSearchKey(parsedSearch)],
	});
};

export const Route = createFileRoute('/category/_category/$')({
	component: CategoryPage,
	loader: parameter => {
		loader(parameter);
	},
	validateSearch,
});
