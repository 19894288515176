import { useEffect } from 'react';

import features from '@/vendor/features.ts';

const startCMS = () => {
	const script = document.createElement('script');
	script.src =
		process.env.NODE_ENV === 'production'
			? '/cms/3/cms.js'
			: `https://${features.network.uploadURL}/cms/3/cms.js`;

	script.addEventListener('load', () => {
		// @ts-expect-error
		CMS.on('config:get', promise => {
			promise.resolve({
				app_content_override: ['html', 'image', 'text', 'merchzone'],
				// @ts-expect-error
				currentLocale: SC?.ENVIRONMENT?.currentLanguage?.locale,
				dontSpamConfigGet: true,
				esc_to_login_disabled: false,
				features: [
					'landingPages',
					'categories',
					'customContentTypes',
					'deCategories',
					'pageTypes',
					'layoutSelector',
					'customerSegmentPreview',
					'themeCustomizerSkinSaver',
					'pageTypeLayoutSelector',
					'cmsMultiLanguage',
					'cmsMultiLanguagePages',
				],
				useSoftNavigation: true,
			});
		});
		// @ts-expect-error
		CMS.trigger('app:ready');
		// @ts-expect-error
		CMS.trigger('app:page:changed', {
			page_type: 'home-page',
			page_type_display_name: 'Home',
			path: '/',
			site_id: 2,
		});
	});

	document.head.append(script);
};

export const useCMS = () => {
	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && event.shiftKey) {
				event.preventDefault();
				event.stopPropagation();
				startCMS();
				document.body.removeEventListener('keydown', listener);
			}
		};

		// If we are on an iframe, start the CMS
		if (window.self === window.top) {
			document.body.addEventListener('keydown', listener);
			return () => {
				document.body.removeEventListener('keydown', listener);
			};
		} else {
			startCMS();
		}
	}, []);
};
