import { IconStar } from '@tabler/icons-react';
import React, { useState } from 'react';

import styles from './Stars.module.css';

interface StarPickerProperties {
	className?: string;
	selectable?: boolean;
	value?: number;
}

const Stars: React.FC<StarPickerProperties> = ({ className, selectable, value }) => {
	const [selectedStars, setSelectedStars] = useState(value ?? 0);

	const handleStarPress = (starIndex: number) => {
		setSelectedStars(starIndex);
	};

	return (
		<div className={`${styles.container} ${className ?? ''}`}>
			{Array.from({ length: 5 }, (_, index) =>
				selectable ? (
					<button
						className={styles.button}
						disabled={value !== undefined}
						key={index}
						onClick={() => handleStarPress(index + 1)}
					>
						<IconStar
							className={`${styles.star} ${selectedStars <= index ? styles.emptyStar : ''}`}
						/>
					</button>
				) : (
					<IconStar
						className={`${styles.star} ${Number(value) <= index ? styles.emptyStar : ''}`}
						data-index={index}
						key={index}
					/>
				),
			)}
		</div>
	);
};

export default Stars;
