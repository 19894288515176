import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';

import styles from './Switch.module.css';

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...properties }, reference) => (
	<SwitchPrimitives.Root
		className={`${styles.switchRoot} ${className ?? ''}`}
		{...properties}
		ref={reference}
	>
		<SwitchPrimitives.Thumb className={styles.switchThumb} />
	</SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export default Switch;
