import React from 'react';

import { useCMSQuery } from '@/stores/CMSHooks.ts';
import features from '@/vendor/features.ts';

type CMSAreaHTMLProperties = {
	name: string;
	pageType: string;
	path: string;
} & React.HTMLAttributes<HTMLDivElement>;

// TODO for links here, using a useeffect link to the click event and use router navigate since regular a tags dont work
export const CMSAreaHTML: React.FC<CMSAreaHTMLProperties> = ({
	name,
	pageType,
	path,
	...properties
}) => {
	const data = useCMSQuery(path, pageType, 'CMS_HTML', name);
	const html = data
		?.map(item => item.fields.custrecord_html_clob_html)
		.join('')
		.replaceAll(' src="/', ` src="https://${features.network.uploadURL}/`);

	return (
		<div
			{...properties}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{ __html: html }}
			data-cms-area={name}
			data-cms-area-filters="path"
		/>
	);
};

export default CMSAreaHTML;
