import { IconX } from '@tabler/icons-react';
import React from 'react';

import SearchLink from '@/components/SearchLink/SearchLink.tsx';
import { FacetType } from '@/stores/CacheStore.ts';
import { formatFacetUrl } from '@/utils/functions.ts';

import styles from './SelectedFacets.module.css';

export const SelectedFacets: React.FC<{
	facets: FacetType[];
	from: '/category/$' | '/search';
	params?: Record<string, any>;
	search: Record<string, any | string>;
}> = ({ facets, from, params, search }) => {
	const visibleParameters = Object.entries(search).filter(
		([key]) => key !== 'q' && key !== 'sort' && key !== 'p',
	);
	if (visibleParameters.length === 0) {
		return null;
	}
	return (
		<div className={styles.container}>
			{facets
				? visibleParameters.flatMap(([key, value]) => {
						const facet = facets.find(facet => facet.url === key);
						if (!facet) return null;
						const values = typeof value === 'string' ? value.split(',') : [value];
						return values.map(value => {
							const parsedValue = facet.values.find(v => v.url === value)?.label;
							return (
								<SearchLink
									className={styles.facet}
									currentSearch={search}
									from={from}
									key={value}
									params={params}
									search={oldSearch => ({
										...oldSearch,
										[key]: values.filter(v => v !== value).join(','),
									})}
									to={from}
								>
									<span className={styles.facetLabel}>{formatFacetUrl(facet.id)}:</span>
									<span>{parsedValue ?? value}</span>
									<IconX />
								</SearchLink>
							);
						});
					})
				: null}
		</div>
	);
};

export default SelectedFacets;
