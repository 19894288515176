import { IconLoader2 } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';

import { Item } from '@/customTypes/Item';
import { getImageWithOptions } from '@/utils/Item';

import styles from './ImageShower.module.css';

// TODO we can prevent the switch from happening until the next image loads
const ImageShower: React.FC<{
	className?: string;
	item?: Item;
	mouseInside: boolean;
	options?: Record<string, string | undefined>;
	resize?: number;
}> = ({ className, item, mouseInside, options, resize }) => {
	const [mouseEntered, setMouseEntered] = useState(false);
	const imageInterval = 750;
	const images = useMemo(() => getImageWithOptions(item, options, resize), [item, options, resize]);
	const [currentIndex, setCurrentIndex] = useState(0);

	// Handle mouse enter and leave from parent
	useEffect(() => {
		if (mouseInside) {
			setMouseEntered(true);
			if (images.length > 1) {
				setCurrentIndex(1);
			}
		} else {
			setCurrentIndex(0);
		}
	}, [images.length, mouseInside]);

	// Change from one image to the next while hovered
	useEffect(() => {
		if (mouseInside) {
			const interval = setInterval(() => {
				setCurrentIndex(previousIndex => {
					if (previousIndex < images.length - 1) {
						return previousIndex + 1;
					} else {
						clearInterval(interval);
						return previousIndex;
					}
				});
			}, imageInterval);

			return () => clearInterval(interval);
		}
	}, [images.length, mouseInside, imageInterval]);

	const progressBarWidth =
		images.length > 1 ? `${(currentIndex / (images.length - 1)) * 100}%` : '0%';

	return (
		<div className={`${className ?? ''} ${mouseInside ? styles.hover : ''} ${styles.wrapper}`}>
			<div
				className={styles.container}
				style={{ transform: `translateX(-${currentIndex * 100}%)` }}
			>
				{item ? (
					mouseEntered && images.length > 1 ? (
						images.map((image, index) => (
							<img
								alt={image}
								className={styles.image}
								key={`${item?.internalId}-${index}`}
								src={image}
							/>
						))
					) : (
						<img alt={item?.name ?? 'Loading'} className={styles.image} src={images[0]} />
					)
				) : (
					<IconLoader2 className={`${styles.loaderIcon} loader`} />
				)}
			</div>
			<div
				className={`${styles.progressBarContainer} ${
					mouseInside && images.length > 1 ? styles.hover : ''
				}`}
			>
				<div className={styles.progressBar} style={{ width: progressBarWidth }} />
			</div>
		</div>
	);
};

export default ImageShower;
