import { IconLoader2, IconSearch, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import React, { useEffect, useMemo, useState } from 'react';

import Conditional from '@/components/Conditional/Conditional';
import SearchLink from '@/components/SearchLink/SearchLink.tsx';
import { Item } from '@/customTypes/Item';
import { useCacheStore, useStateStore } from '@/stores';
import { getAllImages, getItems } from '@/utils/Item';

import styles from './SearchBar.module.css';

const MAX_ITEMS = 5;

// TODO rethink how the focus works, maybe check if the element is inside the main div with a reference
const SearchBar: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	const q = useSearch({
		select: ({ q }) => q,
		strict: false,
	}) as string;
	const [search, setSearch] = useState<string>(q ?? '');
	const [focused, setFocused] = useState(false);
	const [showItems, setShowItems] = useState(false);
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const { data, error, isLoading } = useQuery<{ items: Item[] }>({
		enabled: showItems && search.length > 2,
		queryFn: async ({ signal }) =>
			getItems(
				{
					fieldset: 'typeahead',
					limit: MAX_ITEMS,
					offset: 0,
					q: search,
					sort: 'relevance:desc',
				},
				signal,
			),
		queryKey: ['items-typeahead', search],
	});
	const lastSearched = useStateStore(store => store.state.lastSearched);
	const addToLastSearched = useStateStore(store => store.addToLastSearched);
	const removeFromLastSearched = useStateStore(store => store.removeFromLastSearched);

	useEffect(() => {
		if (error) {
			console.log(error);
		}
	}, [error]);

	useEffect(() => {
		if (search.length > 2 && focused) {
			const timeout = setTimeout(() => {
				setShowItems(true);
			}, 200);

			return () => clearTimeout(timeout);
		}
		setShowItems(false);
	}, [search, setShowItems, focused]);

	const lasSearchedToShow = useMemo(
		() =>
			lastSearched
				.filter(value => value.toLowerCase().includes(search.toLowerCase()))
				.slice(0, 5)
				.map(search => (
					<Link
						className={styles.lastSearched}
						data-last-searched
						key={search}
						onClick={() => {
							addToLastSearched(search);
							setShowItems(false);
							setFocused(false);
							setSearch(search);
						}}
						params={{}}
						search={{
							p: undefined,
							q: search,
						}}
						to="/search"
					>
						<span>{search}</span>
						<button
							aria-label="Remove search"
							className={styles.removeButton}
							data-last-searched
							onClick={event => {
								event.preventDefault();
								event.stopPropagation();
								removeFromLastSearched(search);
								console.log('remove');
							}}
						>
							<IconX />
						</button>
					</Link>
				)),
		[addToLastSearched, lastSearched, removeFromLastSearched, search],
	);
	const items = useMemo(
		() =>
			data?.items.length === 0 ? (
				<span className={styles.noResultsText}>No results found</span>
			) : (
				data?.items.map(item => (
					<Link
						className={styles.suggestion}
						data-typeahead-item
						key={item.internalId}
						onClick={() => {
							addToLastSearched(search);
							setShowItems(false);
							setFocused(false);
							setSimplifiedItem(item);
						}}
						params={{ itemURL: item.url }}
						search={{}}
						to="/item/$itemURL"
					>
						<img
							alt={item.name}
							className={styles.suggestionImage}
							src={getAllImages(item, 4)[0]}
						/>
						<label className={styles.suggestionText}>{item.name}</label>
					</Link>
				))
			),
		[addToLastSearched, data?.items, search, setSimplifiedItem],
	);

	// TODO make the input 100% width and use absolute positioning for the buttons
	const typeaheadOpen = showItems && !isLoading;
	return (
		<div className={`${styles.container} ${className ?? ''}`}>
			<div className={styles.searchContainer}>
				<input
					onBlur={event => {
						const isSearch = event.relatedTarget as HTMLElement | null;
						if (isSearch?.dataset.typeaheadItem || isSearch?.dataset.lastSearched) {
							return;
						}
						setShowItems(false);
						setFocused(false);
					}}
					onFocus={() => {
						setFocused(true);
						setShowItems(search.length > 2);
					}}
					onInput={event => setSearch(event.currentTarget.value)}
					onKeyDown={async event => {
						if (event.key === 'Enter' && search.length > 2) {
							addToLastSearched(search);
							event.preventDefault();
							setShowItems(false);
							if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
							await navigate({ search: { p: undefined, q: search }, to: '/search' });
						}
					}}
					placeholder="Search for products..."
					value={search}
				/>
				<Conditional visible={search.length > 0}>
					{isLoading ? (
						<IconLoader2 className={`${styles.loader} loader`} />
					) : (
						<button
							aria-label="Clear search"
							className={styles.clearButton}
							onClick={() => {
								setSearch('');
								setShowItems(false);
							}}
						>
							<IconX />
						</button>
					)}
				</Conditional>
				<SearchLink
					aria-label="Search"
					className={styles.searchButton}
					currentSearch={{}}
					disabled={search.length < 3 || data?.items.length === 0}
					onClick={() => {
						addToLastSearched(search);
						setShowItems(false);
						setFocused(false);
					}}
					params={{}}
					search={() => ({ p: undefined, q: search })}
					to="/search"
				>
					<IconSearch size={18} />
				</SearchLink>
			</div>
			<div
				className={styles.items}
				style={{
					maxHeight: typeaheadOpen
						? // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
							`calc(var(--suggestion-height) * ${data?.items.length || 1})`
						: 0,
				}}
			>
				{items}
			</div>
			<div
				className={styles.lastSearchedContainer}
				style={{
					maxHeight:
						focused && !typeaheadOpen ? `calc(var(--suggestion-height) * ${lasSearchedToShow})` : 0,
				}}
			>
				{lasSearchedToShow}
			</div>
		</div>
	);
};

export default SearchBar;
