import React, { useState } from 'react';

import ItemComponent from '@/components/ItemComponent/ItemComponent';
import MultiAddToCart from '@/components/MultiAddToCart/MultiAddToCart.tsx';
import Pagination from '@/components/Pagination/Pagination';
import { Item } from '@/customTypes/Item';

import styles from './PaginatedSearch.module.css';

export const PaginatedSearch: React.FC<{
	from: '/category/$' | '/search';
	items?: Item[];
	page: number;
	pageSize: number;
	search: Record<string, any>;
	total?: number;
}> = ({ from, items, page, pageSize, search, total }) => {
	const parsedItems =
		items ??
		Array.from({ length: total ? Math.min(pageSize, total - (page - 1) * pageSize) : pageSize });
	const totalPages = Math.ceil((total ?? 0) / pageSize);
	const [quantities, setQuantities] = useState<Map<Item, number>>(new Map());
	return (
		<div>
			<div className={styles.container}>
				{parsedItems.length === 0 ? (
					<h2 className={styles.noResultsText}>No results found</h2>
				) : (
					parsedItems.map((item, index) => (
						<ItemComponent
							item={item}
							key={item?.internalId ?? index}
							onQuantityChange={(item, quantity) => {
								const newMap = new Map(quantities);
								newMap.set(item, quantity);
								setQuantities(newMap);
							}}
							quantity={quantities.get(item)}
						/>
					))
				)}
			</div>
			<Pagination from={from} page={page} pages={totalPages} search={search} />
			<MultiAddToCart afterAdd={() => setQuantities(new Map())} quantities={quantities} />
		</div>
	);
};

export default PaginatedSearch;
