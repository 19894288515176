import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DependencyList, useCallback } from 'react';

import { Address, Order } from '@/customTypes/Order';
import Profile, { CreditCardInfo } from '@/customTypes/Profile';
import { orderQueryOptions, parseOrder } from '@/stores/OrderHooks.ts';
import { customFetch } from '@/utils/network.ts';
import features from '@/vendor/features.ts';

export const profileQueryOptions: UseQueryOptions<Profile, unknown, Profile> = {
	queryFn: async () => {
		const response = await customFetch<Profile>({
			headers: {
				'X-SC-Touchpoint': 'shopping',
			},
			url: '/services/Profile.Service.ss',
		});
		// @ts-expect-error todo make global this type work
		globalThis.SC.PROFILE = response;

		return {
			...response,
			loading: false,
		};
	},
	queryKey: ['profile'],
	staleTime: 1000 * 60,
};

// Fetches a slice of the current profile and re-renders when the slice changes
// If your selector function depends on other variables, you can pass them as a second argument
export const useProfileQuery = <T>(
	selector: (order?: Profile) => T,
	selectorDeps: DependencyList = [],
) => {
	const query = useQuery<Profile, unknown, T>({
		...profileQueryOptions,
		notifyOnChangeProps: ['data'],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		select: useCallback(selector, selectorDeps),
	});
	return query.data as T;
};

export const useProfileLoading = () => {
	const query = useQuery({
		...profileQueryOptions,
		notifyOnChangeProps: ['isLoading'],
	});
	const dataQuery = useQuery<Profile, unknown, boolean>({
		...profileQueryOptions,
		notifyOnChangeProps: ['data'],
		select: useCallback((data: Profile) => data.loading, []),
	});
	return query.isLoading || dataQuery.data;
};

interface LoginResponse {
	address: Address[];
	cart: Order;
	creditcard: CreditCardInfo[];
	errorMessage?: string;
	user: Profile;
}

export const useLoginMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<LoginResponse, unknown, { email: string; password: string; token?: string }>({
		mutationFn: async ({ email, password, token }) => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: true,
			});
			const response = await customFetch<LoginResponse>({
				body: {
					email,
					'grecaptcha-token': token,
					password,
				},
				headers: {
					'X-SC-Touchpoint': 'checkout',
				},
				method: 'POST',
				url: '/services/Account.Login.Service.ss?n=2&c=' + features.network.accountID,
			});
			if (response.errorMessage) {
				throw response.errorMessage;
			}
			return response;
		},
		onError: () => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: false,
			});
		},
		onSuccess: data => {
			queryClient.invalidateQueries({ queryKey: ['wishlists'] });
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, {
				...data.user,
				addressbook: data.address,
				creditcards: data.creditcard,
				loading: false,
			});
			queryClient.setQueryData(orderQueryOptions.queryKey, parseOrder(data.cart));
			// @ts-expect-error todo make global this type work
			globalThis.SC.PROFILE = data;
		},
	});
};

export const useRegisterMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<
		Profile,
		unknown,
		{
			firstName: string;
			lastName: string;
			registerEmail: string;
			registerPassword: string;
			registerPasswordConfirm: string;
			token?: string;
		}
	>({
		mutationFn: async ({
			firstName,
			lastName,
			registerEmail,
			registerPassword,
			registerPasswordConfirm,
			token,
		}) => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: true,
			});
			return customFetch<{ errorMessage?: string; user: Profile }>({
				body: {
					email: registerEmail,
					firstname: firstName,
					'grecaptcha-token': token,
					lastname: lastName,
					password: registerPassword,
					redirect: 'true',
					registerPasswordConfirm,
				},
				method: 'POST',
				url: '/services/Account.Register.Service.ss',
			}).then(response => {
				if (!response.user) {
					throw response.errorMessage;
				}
				return response.user;
			});
		},
		onError: () => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: false,
			});
		},
		onSuccess: data => {
			queryClient.invalidateQueries({ queryKey: ['wishlists'] });
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, {
				...data,
				loading: false,
			});
			queryClient.invalidateQueries(orderQueryOptions);
		},
	});
};

export const useLogOutMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: () => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: true,
			});
			return customFetch({
				text: true,
				url: '/logOut.ssp',
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(profileQueryOptions);
			queryClient.invalidateQueries(orderQueryOptions);
			queryClient.invalidateQueries({ queryKey: ['wishlists'] });
			// @ts-expect-error todo make global this type work
			globalThis.SC.PROFILE = undefined;
		},
	});
};

export const useAddressMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<Address, string, Address, { previousProfile?: Profile }>({
		mutationFn: async address => {
			const isUpdate = !!address.internalid;
			const response = await customFetch<{ errorMessage: string } & Address>({
				body: {
					...address,
					fullname: address.addressee,
					isresidential: 'F',
				},
				method: isUpdate ? 'PUT' : 'POST',
				parameters: {
					internalid: address.internalid,
				},
				url: '/services/Address.Service.ss',
			});
			if (response.errorMessage) {
				throw response.errorMessage;
			}
			return response;
		},
		onError: (error, _, context) => {
			// Rollback to the previous state if mutation fails
			if (context?.previousProfile) {
				queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, context.previousProfile);
			}

			queryClient.setQueryData(orderQueryOptions.queryKey, {
				...queryClient.getQueryData(orderQueryOptions.queryKey),
				loading: false,
			});

			console.error('Failed to save address:', error);
		},
		onMutate: async address => {
			// Cancel any outgoing refetches to avoid overwriting our optimistic update
			await queryClient.cancelQueries(profileQueryOptions);
			await queryClient.cancelQueries(orderQueryOptions);
			queryClient.setQueryData(orderQueryOptions.queryKey, {
				...queryClient.getQueryData(orderQueryOptions.queryKey),
				loading: true,
			});

			// Snapshot the previous profile data
			const previousProfile = queryClient.getQueryData<Profile>(profileQueryOptions.queryKey);

			// Perform optimistic update
			const isUpdate = !!address.internalid;
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, oldData => {
				if (!oldData) return oldData;

				return isUpdate
					? {
							...oldData,
							addressbook: oldData.addressbook?.map(addr =>
								addr.internalid === address.internalid ? { ...addr, ...address } : addr,
							),
						}
					: {
							...oldData,
							addressbook: [
								...(oldData.addressbook || []),
								{ ...address, internalid: 'temp-' + Date.now() },
							],
						};
			});

			// Return the snapshot so we can rollback on error
			return { previousProfile };
		},
		onSuccess: (data, variables) => {
			const isUpdate = !!variables.internalid;

			// Update with the actual server data
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, oldData => {
				if (!oldData) return oldData;

				return isUpdate
					? {
							...oldData,
							addressbook: oldData.addressbook?.map(address =>
								address.internalid === variables.internalid
									? { ...address, ...variables, ...data }
									: address,
							),
						}
					: {
							...oldData,
							addressbook: [
								...(oldData.addressbook?.filter(addr => !addr.internalid?.startsWith('temp-')) ||
									[]),
								{ ...variables, ...data },
							],
						};
			});

			queryClient.setQueryData(orderQueryOptions.queryKey, {
				...queryClient.getQueryData(orderQueryOptions.queryKey),
				loading: false,
			});
		},
	});
};

export interface UpdateProfile {
	email: string;
	firstname: string;
	lastname: string;
	phone: string;
}

export const useUpdateProfileMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<Profile, unknown, UpdateProfile>({
		mutationFn: async data => {
			// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
			const currentData = queryClient.getQueryData(profileQueryOptions.queryKey) as Profile;
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...currentData,
				loading: true,
			});

			return customFetch<{ errorMessage?: string } & Profile>({
				body: {
					...currentData,
					...data,
				},
				method: 'PUT',
				parameters: {
					internalid: currentData.internalid,
				},
				url: '/services/Profile.Service.ss',
			}).then(response => {
				if (response.errorMessage) {
					throw response.errorMessage;
				}
				return response;
			});
		},
		onError: () => {
			queryClient.setQueryData(profileQueryOptions.queryKey, {
				...queryClient.getQueryData(profileQueryOptions.queryKey),
				loading: false,
			});
		},
		onSuccess: data => {
			queryClient.setQueryData<Profile>(profileQueryOptions.queryKey, {
				...data,
				loading: false,
			});
			// If profile update might affect order data
			queryClient.invalidateQueries(orderQueryOptions);

			// Update global state if needed
			// @ts-expect-error todo make global this type work
			if (globalThis.SC?.PROFILE) {
				// @ts-expect-error todo make global this type work
				globalThis.SC.PROFILE = {
					// @ts-expect-error todo make global this type work
					...globalThis.SC.PROFILE,
					...data,
				};
			}
		},
	});
};
