import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import ItemCarousel from '@/components/ItemCarousel/ItemCarousel';
import { useStateStore } from '@/stores';
import { getItems } from '@/utils/Item.ts';

const LastViewed: React.FC = () => {
	const lastViewed = useStateStore(store => store.state.lastViewed);
	const { data } = useQuery({
		queryFn: () =>
			getItems({
				fieldset: 'relateditems',
				id: lastViewed,
				limit: lastViewed.length,
				offset: 0,
			}),
		queryKey: ['last-viewed', lastViewed],
		staleTime: 1000 * 60 * 10,
	});

	return <ItemCarousel items={data?.items ?? []} />;
};

export default LastViewed;
