import { Link } from '@tanstack/react-router';
import React from 'react';

import Button from '@/components/Button/Button.tsx';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogTitle,
} from '@/components/Dialog/Dialog.tsx';
import ImageShower from '@/components/ImageShower/ImageShower.tsx';
import Price from '@/components/Price/Price.tsx';
import { useStateStore } from '@/stores';
import features from '@/vendor/features.ts';

import styles from './AddedToCartModal.module.css';

export const AddedToCartModal: React.FC = () => {
	const latestAdditions = useStateStore(store => store.state.latestAdditions);
	const setState = useStateStore(store => store.setState);

	if (!features.addToCartModal) {
		return null;
	}

	return (
		<Dialog
			onOpenChange={open => {
				if (!open) {
					setState({ latestAdditions: undefined });
				}
			}}
			open={!!latestAdditions}
		>
			<DialogContent>
				<DialogTitle>
					Successfully added the following item
					{latestAdditions && latestAdditions?.length > 1 ? 's' : ''} to the cart
				</DialogTitle>
				<DialogDescription>
					{latestAdditions?.map(addition => {
						const parsedOptions: Record<string, string> = {};
						for (const option of Object.keys(addition.options ?? {})) {
							const optionInfo = addition.item.options.find(
								opt => opt.internalid === `custcol_${option}`,
							)!;
							parsedOptions[optionInfo.label] =
								optionInfo.values?.find(
									value => value.internalid === String(addition.options[option]),
								)?.label ?? '';
						}
						return (
							<DialogClose asChild key={addition.item.internalId}>
								<Link
									className={styles.item}
									params={{ itemURL: addition.item.url }}
									search={addition.options}
									to="/item/$itemURL"
								>
									<ImageShower
										className={styles.image}
										item={addition.item}
										mouseInside={false}
										options={addition.options}
									/>
									<div className={styles.itemInfo}>
										<label>{addition.addedItem.name}</label>
										<Price item={addition.item} size="small" />
										<span>Quantity: {addition.quantity}</span>
										{Object.keys(parsedOptions).length > 0 ? (
											<>
												<span>Options:</span>
												<div>
													{Object.entries(parsedOptions).map(([key, value]) => (
														<span key={key}>
															{key}: {value}
															<br />
														</span>
													))}
												</div>
											</>
										) : null}
									</div>
								</Link>
							</DialogClose>
						);
					})}
				</DialogDescription>
				<DialogFooter>
					<DialogClose asChild>
						<Button asChild>
							<Link to="/cart">View Cart</Link>
						</Button>
					</DialogClose>
					<DialogClose asChild>
						<Button asChild variant="secondary">
							<Link to="/checkout/opc">Go To Checkout</Link>
						</Button>
					</DialogClose>
					<DialogClose asChild>
						<Button variant="secondary">Continue Shopping</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddedToCartModal;
