import { IconLoader2 } from '@tabler/icons-react';
import { Link, useRouter } from '@tanstack/react-router';
import React from 'react';

import Button from '@/components/Button/Button.tsx';
import { useOrderQuery } from '@/stores/OrderHooks.ts';
import { useProfileLoading, useProfileQuery } from '@/stores/ProfileHooks.ts';

import styles from './GoToCheckoutButton.module.css';
export const GoToCheckoutButton: React.FC<{
	onClick?: () => void;
}> = ({ onClick }) => {
	const lines = useOrderQuery(order => order?.lines.length ?? 0);
	const loggedIn = useProfileQuery(profile => profile?.isLoggedIn === 'T');
	const router = useRouter();
	const loading = useProfileLoading();

	const getContent = () => {
		if (loading) {
			return (
				<>
					<IconLoader2 className="loader" />
					<span>Loading...</span>
				</>
			);
		}
		if (lines === 0) {
			return 'Add something to your cart first';
		}
		if (loggedIn) {
			return 'Continue to Checkout';
		}

		return 'Login/Register to Checkout';
	};

	return (
		<Button asChild disabled={lines === 0 || loading} variant="secondary">
			<Link
				className={styles.button}
				onClick={onClick}
				params={{}}
				search={loggedIn ? {} : { to: router.state.location.href }}
				to={loggedIn ? '/checkout/opc' : '/login'}
			>
				{getContent()}
			</Link>
		</Button>
	);
};

export default GoToCheckoutButton;
