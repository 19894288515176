import { IconSearch } from '@tabler/icons-react';
import React, { useState } from 'react';

import Button from '@/components/Button/Button';
import Conditional from '@/components/Conditional/Conditional';
import { Dialog, DialogContent } from '@/components/Dialog/Dialog';
import ImageShower from '@/components/ImageShower/ImageShower';
import ItemLink from '@/components/ItemLink/ItemLink.tsx';
import NumberInput from '@/components/NumberInput/NumberInput.tsx';
import Price from '@/components/Price/Price.tsx';
import QuickView from '@/components/QuickView/QuickView';
import Stars from '@/components/Stars/Stars.tsx';
import Tags from '@/components/Tags/Tags.tsx';
import { Item } from '@/customTypes/Item';
import { useCacheStore } from '@/stores';

import styles from './ItemComponent.module.css';

interface ItemComponentProperties {
	className?: string;
	item?: Item;
	onQuantityChange?: (item: Item, quantity: number) => void;
	quantity?: number;
}

const ItemComponent: React.FC<ItemComponentProperties> = ({
	className,
	item,
	onQuantityChange,
	quantity,
}) => {
	const [mouseInside, setMouseInside] = useState(false);
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const [open, setOpen] = useState(false);

	return (
		<ItemLink
			className={`${styles.container} ${className ?? ''}`}
			disabled={!item}
			draggable={false}
			item={item}
			onClick={() => {
				setSimplifiedItem(item!);
			}}
			onDragStart={event => event.preventDefault()}
			onMouseEnter={() => setMouseInside(true)}
			onMouseLeave={() => setMouseInside(false)}
			search={{}}
		>
			<ImageShower className={styles.image} item={item} mouseInside={mouseInside} resize={2} />
			<span className={styles.name}>{item?.name ?? 'Loading...'}</span>

			{Number(item?.customFields.ns_pr_count) > 0 ? (
				<Stars value={Number(item?.customFields.ns_pr_rating ?? 5)} />
			) : (
				<span className={styles.starsEmpty} />
			)}

			<span className={styles.category}>
				<span>SKU:</span> {item?.itemId ?? 'Loading...'}
			</span>
			<Price item={item} size="small" />
			<Tags item={item} mouseInside={mouseInside} size="small" />
			<Conditional
				visible={
					item && onQuantityChange && item.childItems.length === 0 ? !item.isMatrixParent : null
				}
			>
				{/* TODO como hacemos aca para saber si es matrix o no? */}
				<NumberInput
					className={styles.quantity}
					setValue={value => {
						onQuantityChange?.(item!, typeof value === 'function' ? value(quantity ?? 0) : value);
					}}
					value={quantity ?? 0}
				/>
			</Conditional>
			<Conditional visible={item}>
				<Button
					className={styles.quickView}
					onClick={event => {
						event.stopPropagation();
						event.preventDefault();
						setOpen(true);
					}}
				>
					<IconSearch />
				</Button>
				<Dialog onOpenChange={setOpen} open={open}>
					<DialogContent>
						<QuickView item={item!} onClose={() => setOpen(false)} />
					</DialogContent>
				</Dialog>
			</Conditional>
		</ItemLink>
	);
};

export default ItemComponent;
