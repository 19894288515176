import { Item, SimplifiedItem } from '@/customTypes/Item';
import { createStore, setter } from '@/utils/store';

interface State {
	cartOpen: boolean;
	lastSearched: string[];
	lastViewed: number[];
	latestAdditions?: {
		addedItem: Item | SimplifiedItem;
		item: Item;
		options: Record<string, string | undefined>;
		optionValues: Record<string, string | undefined>;
		quantity: number;
	}[];
	menuOpen: boolean;
	pageWidth: number;
	quickViewItem: Item | SimplifiedItem | undefined;
	searchCache: Record<string, { cache: Record<number, Item[]>; total: number }>;
	typeaheadOpen: boolean;
}

// Global state, meant to be used for interaction between components
// TODO move searchcache to cache store
const state: State = {
	cartOpen: false,
	lastSearched: JSON.parse(localStorage.getItem('last-searched') ?? '[]').slice(0, 5) as string[], // TODO move to a try catch to prevent errors
	lastViewed: JSON.parse(localStorage.getItem('last-viewed') ?? '[]').slice(0, 5) as number[], // TODO move to a try catch to prevent errors
	menuOpen: false,
	pageWidth: window.innerWidth,
	quickViewItem: undefined,
	searchCache: {},
	typeaheadOpen: false,
};

export interface StateStore {
	addToLastSearched: (search: string) => void;
	addToLastViewed: (item: SimplifiedItem) => void;
	removeFromLastSearched: (search: string) => void;
	setState: (profile: Partial<typeof state>) => void;
	state: typeof state;
}

export default createStore<StateStore>((set, get) => {
	const setState: StateStore['setState'] = data => {
		set({
			state: setter(data, get().state),
		});
	};
	window.addEventListener(
		'resize',
		() => {
			setState({
				pageWidth: Math.floor(window.innerWidth),
			});
		},
		{ passive: true },
	);
	return {
		addToLastSearched: search => {
			const items = get().state.lastSearched.filter(item => item !== search);
			items.unshift(search);
			localStorage.setItem('last-searched', JSON.stringify(items.slice(0, 50)));
			setState({ lastSearched: items.slice(0, 50) });
		},
		addToLastViewed: item => {
			const items = get().state.lastViewed;
			if (items.includes(item.internalId)) {
				return;
			}
			items.unshift(item.internalId);
			localStorage.setItem('last-viewed', JSON.stringify(items.slice(0, 5)));
			setState({ lastViewed: items.slice(0, 5) });
		},
		removeFromLastSearched: search => {
			const items = get().state.lastSearched.filter(item => item !== search);
			localStorage.setItem('last-searched', JSON.stringify(items.slice(0, 50)));
			setState({ lastSearched: items.slice(0, 50) });
		},

		setState,
		state,
	};
});
