import { IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import React, { useMemo } from 'react';

import AddToFavoritesButton from '@/components/AddToFavoritesButton/AddToFavoritesButton.tsx';
import Button from '@/components/Button/Button';
import { DialogTitle } from '@/components/Dialog/Dialog';
import ImageGallery from '@/components/ImageGallery/ImageGallery';
import ItemOptions from '@/components/ItemOptions/ItemOptions.tsx';
import NumberInput from '@/components/NumberInput/NumberInput.tsx';
import Price from '@/components/Price/Price.tsx';
import Stars from '@/components/Stars/Stars.tsx';
import { Item } from '@/customTypes/Item';
import { ItemOption as ItemOptionType } from '@/customTypes/itemResponse';
import { useStateStore } from '@/stores';
import { useAddLinesMutation, useOrderLoading } from '@/stores/OrderHooks.ts';
import { getItemToAdd } from '@/utils/functions.ts';
import { getItemInfo } from '@/utils/itemFunctions.tsx';

import styles from './QuickView.module.css';

// TODO we should merge part of this code with the PDP
export const QuickView: React.FC<{ item: Item; onClose?: () => void }> = ({ item, onClose }) => {
	const setState = useStateStore(store => store.setState);
	const { data, error } = useQuery({
		enabled: Boolean(item),
		notifyOnChangeProps: ['data'],
		queryFn: () => getItemInfo(item!.url),
		queryKey: ['item', item!.url],
		staleTime: 1000 * 60 * 10,
	});
	const [quantity, setQuantity] = React.useState(1);
	const [options, setOptions] = React.useState<Record<string, string | undefined>>({});
	const optionsByKey = useMemo(() => {
		const result: Record<string, ItemOptionType> = {};
		for (const option of data?.options ?? []) {
			result[option.internalid.replace('custcol_', '')] = option;
		}
		return result;
	}, [data?.options]);
	const optionValues = useMemo(() => {
		const result: Record<string, string> = {};
		for (const option of Object.keys(options)) {
			result[option] = optionsByKey[option]!.values!.find(
				value => value.internalid === String(options[option]),
			)!.label;
		}
		return result;
	}, [options, optionsByKey]);
	const loading = useOrderLoading();
	const addToCart = useAddLinesMutation();
	const toAdd = useMemo(() => data && getItemToAdd(data, options), [data, options]);

	if (error) {
		return <span>Error getting product info ({error.message})</span>;
	}
	return (
		<div className={styles.modal}>
			<DialogTitle>{item?.name}</DialogTitle>
			<div className={styles.qvWrapper}>
				<ImageGallery
					className={styles.qvCarousel}
					item={item}
					options={optionValues}
					tagSize="small"
				/>
				<div className={styles.qvContainer}>
					<Link
						className={styles.viewFullLink}
						onClick={onClose}
						params={{ itemURL: item?.url ?? '' }}
						search={options}
						to="/item/$itemURL"
					>
						View full details
					</Link>
					<label className={styles.sku}>
						<span className={styles.skuLabel}>SKU: </span>
						{item?.itemId}
					</label>
					<Price item={item} size="large" />
					<div className={styles.qvStars}>
						<Stars value={Number(item?.customFields.ns_pr_rating || 5)} />
						<span>
							{Number(item?.customFields.ns_pr_count || 0) > 0
								? `${item?.customFields.ns_pr_count} reviews`
								: 'No reviews'}
						</span>
					</div>
					<NumberInput setValue={setQuantity} value={quantity} />
					<ItemOptions
						asButtons
						childItems={data?.childItems ?? []}
						className={styles.itemOptions}
						onChange={setOptions}
						options={data?.options ?? []}
						optionsByKey={optionsByKey}
						search={options}
					/>
					<Button
						disabled={!toAdd || loading}
						onClick={() => {
							onClose?.();
							setState({
								latestAdditions: [
									{
										addedItem: toAdd!,
										item: item,
										options: options,
										optionValues,
										quantity,
									},
								],
							});
							addToCart.mutate([
								{
									item: {
										internalid: toAdd!.internalId,
										itemtype: item.type,
										...Object.fromEntries(
											Object.entries(optionValues).map(([key, value]) => [`custcol_${key}`, value]),
										),
									},
									quantity,
								},
							]);
						}}
					>
						{loading ? <IconLoader2 className="loader" /> : null}
						Add to Cart
					</Button>
					<AddToFavoritesButton item={item} />
				</div>
			</div>
		</div>
	);
};

export default QuickView;
