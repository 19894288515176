import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';

import features from '@/vendor/features.ts';
// import { persistQueryClient } from '@tanstack/react-query-persist-client';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 10, // Set default staleTime to 10 minutes
		},
	},
});

export default queryClient;
/*
// Create a local storage persister
const localStoragePersister = createSyncStoragePersister({
	storage: window.localStorage, // or sessionStorage if preferred
});

// Persist the query client
persistQueryClient({
	maxAge: 1000 * 60 * 10, // Store data for 10 minutes
	persister: localStoragePersister,
	// @ts-expect-error fix this, probably version mismatch
	queryClient,
});
*/
if (features.broadcastChannel) {
	// Broadcast the query client to other tabs
	broadcastQueryClient({
		broadcastChannel: 'my-app',
		// @ts-expect-error fix this, probably version mismatch
		queryClient,
	});
}
