import * as DialogPrimitive from '@radix-ui/react-dialog';
import { IconX } from '@tabler/icons-react';
import * as React from 'react';
import { Helmet } from 'react-helmet-async'; // Import your CSS Module

import styles from './Dialog.module.css';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...properties }, reference) => (
	<DialogPrimitive.Overlay
		className={`${styles.dialogOverlay} ${className}`}
		ref={reference}
		{...properties}
	/>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, className, ...properties }, reference) => (
	<DialogPortal>
		<DialogOverlay />
		<DialogPrimitive.Content
			className={`${styles.dialogContent} ${className}`}
			ref={reference}
			{...properties}
		>
			{children}
			<DialogPrimitive.Close className={styles.dialogClose}>
				<IconX />
				<span className="sr-only">Close</span>
			</DialogPrimitive.Close>
		</DialogPrimitive.Content>
	</DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...properties }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={`${styles.dialogHeader} ${className}`} {...properties} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...properties }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={`${styles.dialogFooter} ${className}`} {...properties} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ children, className, ...properties }, reference) => (
	<>
		<DialogPrimitive.Title
			className={`${styles.dialogTitle} ${className}`}
			ref={reference}
			{...properties}
		>
			{children}
		</DialogPrimitive.Title>
		<Helmet>
			<title>{children}</title>
		</Helmet>
	</>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...properties }, reference) => (
	<DialogPrimitive.Description
		className={`${styles.dialogDescription} ${className}`}
		ref={reference}
		{...properties}
	/>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogOverlay,
	DialogPortal,
	DialogTitle,
	DialogTrigger,
};
