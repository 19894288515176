import features from '@/vendor/features.ts';

export const getAssetUrl = (asset: string) => {
	if (process.env.NODE_ENV === 'production') {
		return `/awa-commerce/${features.network.uploadURL}/assets/${asset}`;
	}
	return `/assets/${asset}`;
};

export const preloadImage = (url: string) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.addEventListener('load', () => resolve(img));
		img.addEventListener('error', () => reject(new Error(`Failed to load image: ${url}`)));
		img.src = url;
	});
