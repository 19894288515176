import { IconAdjustments } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import Facets from '@/components/Facets/Facets';
import PaginatedSearch from '@/components/PaginatedSearch/PaginatedSearch';
import SelectedFacets from '@/components/SelectedFacets/SelectedFacets.tsx';
import { queryClient } from '@/stores';
import { getSearchKey } from '@/utils/functions.ts';
import { getItems } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

import styles from './Search.module.css';

export interface SearchParameters {
	[key: string]: number | string | undefined;

	p?: number;
	q: string;
	sort?: string;
}

const Search: React.FC = () => {
	const [openFacets, setOpenFacets] = useState(false);
	const search = Route.useSearch();
	const { data } = useQuery({
		queryFn: () =>
			getItems({
				...search,
				fieldset: 'awa_commerce_search',
				include: 'facets',
				limit: features.search.productsPerPage,
				offset: ((search.p ?? 1) - 1) * features.search.productsPerPage,
			}),
		queryKey: ['search', ...getSearchKey(search)],
	});

	return (
		<main className="containerGrid" style={{ overflow: 'hidden', position: 'relative' }}>
			<Breadcrumb
				links={[
					{
						children: 'Home',
						to: '/',
					},
					{
						children: 'Search',
						search: {
							...search,
							p: undefined,
						},
						to: '/search',
					},
				]}
			/>
			<h1>{search.q ? `Searching for "${search.q}"` : 'Search'}</h1>
			<SelectedFacets facets={data?.facets ?? []} from="/search" search={search} />
			<PaginatedSearch
				from="/search"
				items={data?.items}
				page={search.p ?? 1}
				pageSize={features.search.productsPerPage}
				search={search}
				total={data?.total}
			/>
			<button className={styles.openFacetsButton} onClick={() => setOpenFacets(open => !open)}>
				<IconAdjustments />
				Filter & Sort
			</button>
			<Helmet>
				<title>{search.q ? `Searching for "${search.q}"` : 'Search'}</title>
			</Helmet>
			<Facets
				facets={data?.facets ?? []}
				from="/search"
				open={openFacets}
				setOpenFacets={setOpenFacets}
				sort={search.sort}
			/>
		</main>
	);
};

const validateSearch = (search: Record<string, any>) => {
	const page = Number.parseInt(search.p, 10);
	const parsedSearch: Record<string, string | undefined> = {};
	for (const key in search) {
		parsedSearch[key] = search[key] === '' ? undefined : search[key];
	}

	const result: SearchParameters = {
		...parsedSearch,
		p: Number.isNaN(page) || page <= 1 ? undefined : page,
		q: search.q?.trim() ?? '',
		sort: search.sort,
	};
	return result;
};

export const loader = async ({ location: { search } }: { location: { search: any } }) => {
	const parsedSearch = validateSearch(search);
	return queryClient.fetchQuery({
		queryFn: () =>
			getItems({
				...parsedSearch,
				fieldset: 'awa_commerce_search',
				include: 'facets',
				limit: features.search.productsPerPage,
				offset: ((parsedSearch.p ?? 1) - 1) * features.search.productsPerPage,
			}),
		queryKey: ['search', ...getSearchKey(parsedSearch)],
	});
};

export const Route = createFileRoute('/search/_search/')({
	component: Search,
	loader: parameter => {
		loader(parameter);
	},
	validateSearch,
});
