import React from 'react';

import Conditional from '@/components/Conditional/Conditional.tsx';
import SearchLink from '@/components/SearchLink/SearchLink.tsx';
import { Category } from '@/customTypes/environment';
import features from '@/vendor/features.ts';

import styles from './MenuElement.module.css';

const MenuElement: React.FC<{ item: Category; onClick?: () => void }> = ({ item, onClick }) => (
	<li className={styles.parent}>
		<SearchLink
			className={styles.link}
			currentSearch={{}}
			onClick={() => {
				onClick?.();
			}}
			params={{
				_splat: item.fullurl.replace('/', ''),
			}}
			preload={features.network.menuBarPreload}
			search={() => ({ p: undefined })}
			to="/category/$"
		>
			{item.name}
		</SearchLink>

		<Conditional visible={item.categories.length > 0}>
			<ul className={styles.children}>
				<div className={`${styles.menuWrapper} containerGrid`}>
					<ul className={styles.categoryLinkContainer}>
						{item.categories.map(child => (
							<li key={child.internalid}>
								<SearchLink
									className={styles.link}
									currentSearch={{}}
									onClick={() => {
										onClick?.();
									}}
									params={{
										_splat: child.fullurl.replace('/', ''),
									}}
									search={() => ({ p: undefined })}
									to="/category/$"
								>
									{child.name}
								</SearchLink>
							</li>
						))}
					</ul>
				</div>
			</ul>
		</Conditional>
	</li>
);

export default MenuElement;
