import features from '../vendor/features.ts';

interface fetchOptions {
	body?: object;
	dontAddSSP?: boolean;
	headers?: Record<string, string>;
	method?: 'DELETE' | 'GET' | 'POST' | 'PUT';
	parameters?: any;
	signal?: AbortSignal;
	text?: boolean;
	url: string;
}
/**
 * Wrapper around fetch to make it type safe and proxy requests
 * when developing locally
 * @param options - The options to pass to fetch
 * @returns A json objet defined by the type parameter, or a string if text is true
 */
export const customFetch = async <T>(options: fetchOptions): Promise<T> => {
	let url = window.location.href.startsWith('https')
		? `${options.dontAddSSP ? '' : features.network.ssp}${options.url}`
		: `http://localhost:8081${options.url}`;

	if (options.parameters && Object.keys(options.parameters).length > 0) {
		const queryParameters = new URLSearchParams();
		addParameters(queryParameters, options.parameters);
		url += '?' + queryParameters.toString();
	}

	const response = await fetch(url, {
		body: JSON.stringify(options.body),
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			'X-Requested-With': 'XMLHttpRequest',
			...options.headers,
		},
		method: options.method ?? 'GET',
		signal: options.signal,
	});
	if (!response.ok) {
		const body = await response.text();
		console.error(body, response);
		throw new Error(response.statusText ?? body);
	}

	const body = await (options.text ? response.text() : response.json());

	if (typeof body === 'object' && body.errorMessage) {
		throw new Error(body.errorMessge);
	}

	return body;
};

/**
 * Add parameters to a query string, used to generate parameters for objects with object properties
 * @param queryParameters - The query string to add the parameters to
 * @param parameters - The parameters to add
 * @param prefix - The prefix to add to the parameters
 */
const addParameters = (queryParameters: URLSearchParams, parameters: any, prefix = '') => {
	for (const key in parameters) {
		if (Object.prototype.hasOwnProperty.call(parameters, key)) {
			const value = parameters[key];
			const fullKey = prefix ? `${prefix}[${key}]` : key;
			if (value !== null && typeof value === 'object') {
				if (Array.isArray(value)) {
					queryParameters.append(fullKey, value.join(','));
				} else {
					addParameters(queryParameters, value, fullKey);
				}
			} else if (value !== undefined) {
				queryParameters.append(fullKey, value!.toString());
			}
		}
	}
};
