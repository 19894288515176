import React from 'react';

import CustomLink, { CustomLinkProperties } from '@/components/CustomLink/CustomLink.tsx';
import { loader as categoryLoader } from '@/routes/category/_category.$.tsx';
import { loader as searchLoader } from '@/routes/search/_search.index.tsx';
import { preloadImage } from '@/utils/assets.ts';
import { getSearchKey } from '@/utils/functions.ts';
import { getAllImages } from '@/utils/Item.ts';
import features from '@/vendor/features.ts';

export type SearchLinkProperties = {
	currentSearch: Record<string, any>;
	params?: Record<string, any>;
	search: (search: Record<string, any>) => Record<string, any>;
	to: '/category/$' | '/search';
} & Omit<CustomLinkProperties, 'params' | 'search' | 'to'>;

// Custom link component to use for search/category links that preloads images after preloadSearchImagesDelay
const SearchLink: React.FC<SearchLinkProperties> = ({
	currentSearch,
	params,
	search,
	to,
	...properties
}) => {
	const newSearch = search(currentSearch);

	return (
		<CustomLink
			{...properties}
			params={params}
			search={newSearch}
			secondaryDelay={features.network.preloadSearchImagesDelay}
			secondaryFetch={async () => {
				const loader = to === '/search' ? searchLoader : categoryLoader;
				const data = await loader({ location: { search: newSearch }, params } as any);
				const start = performance.now();
				await Promise.all(data.items.map(item => preloadImage(getAllImages(item, 2)[0])));
				console.log(
					`Successfully preloaded ${data?.items.length} images for ${params?._splat ? `category ${params?._splat}` : 'search'}/${getSearchKey(
						newSearch,
					)} in ${Math.floor(performance.now() - start)}ms`,
				);
			}}
			to={to}
		/>
	);
};

export default SearchLink;
