// Define the dataLayer type
// @ts-expect-error
interface Window {
	dataLayer: any[];
}

// Extend the global Window object
declare global {
	interface Window {
		dataLayer: any[];
	}
}

export const initGTM = (id: string): void => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'gtm.js',
			'gtm.start': Date.now(),
		});

		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
		document.head.append(script);
	}
};

// Create a type for common event properties
interface GTMEvent {
	[key: string]: any;
	event: string;
}

// Generic type for different event types
interface ViewItemEvent extends GTMEvent {
	ecommerce: {
		items: {
			[key: string]: any;
			item_id: string;
			item_name: string;
			price: number;
		}[];
	};
	event: 'view_item';
}

interface PurchaseEvent extends GTMEvent {
	ecommerce: {
		items: {
			[key: string]: any;
			item_id: string;
			item_name: string;
			price: number;
			quantity: number;
		}[];
		transaction_id: string;
		value: number;
	};
	event: 'purchase';
}

// Union type of all possible events
type DataLayerEvent = GTMEvent | PurchaseEvent | ViewItemEvent;

export const pushEvent = (event: DataLayerEvent): void => {
	if (typeof window !== 'undefined') {
		window.dataLayer?.push(event);
	}
};
