import { IconLoader2 } from '@tabler/icons-react';
import { useSearch } from '@tanstack/react-router';
import React from 'react';

import Button from '@/components/Button/Button';
import { Item } from '@/customTypes/Item';
import { useStateStore } from '@/stores';
import { useAddLinesMutation, useOrderLoading } from '@/stores/OrderHooks.ts';

interface AddToCartButtonProperties {
	item?: Item;
	onError?: (error: string) => void;
	optionValues: Record<string, string | undefined>;
	quantity: number;
	setQuantity: (quantity: number) => void;
	toAdd?: Item;
}

const AddToCartButton: React.FC<AddToCartButtonProperties> = ({
	item,
	onError,
	optionValues,
	quantity,
	setQuantity,
	toAdd,
}) => {
	const search = useSearch({ strict: false });
	const addToCart = useAddLinesMutation();
	const loadingCart = useOrderLoading();
	const setState = useStateStore(store => store.setState);
	const enabled = toAdd && (toAdd.inStock || toAdd.isBackorderable) && !toAdd.isMatrixParent;

	const handleAddToCart = () => {
		if (!toAdd) {
			throw new Error('No item to add');
		}
		setQuantity(1);

		setState({
			latestAdditions: [
				{
					addedItem: toAdd,
					item: item!,
					options: search,
					optionValues,
					quantity,
				},
			],
		});
		// TODO other item options
		addToCart
			.mutateAsync([
				{
					item: {
						internalid: toAdd.internalId,
						itemtype: 'InvtPart',
					},
					quantity,
				},
			])
			.catch(error => {
				onError?.(error);
			});
	};

	return (
		<Button disabled={!enabled || !toAdd || loadingCart} onClick={handleAddToCart}>
			{loadingCart ? <IconLoader2 className="loader" /> : null}
			Add to Cart
		</Button>
	);
};

export default AddToCartButton;
