import React from 'react';

import Conditional from '@/components/Conditional/Conditional.tsx';
import { Item } from '@/customTypes/Item';
import features from '@/vendor/features.ts';

import styles from './Tags.module.css';

export type TagSizes = 'large' | 'medium' | 'small';

// TODO add image/svg tag
export const Tags: React.FC<{
	item?: Item;
	mouseInside?: boolean;
	size?: TagSizes;
}> = ({ item, mouseInside, size }) => (
	<div className={styles.container}>
		{features.tags.map(tag => (
			<Conditional key={tag.field} visible={item?.customFields[tag.field] ? true : null}>
				<span
					className={`
						${styles.tag} 
						${styles[size ?? 'medium']} 
						${styles[tag.shape ?? 'rectangle']}
						${styles[tag.position ?? 'left']}
						${mouseInside ? styles.mouseInside : ''}
					`}
					// @ts-expect-error
					style={{ '--tag-color': tag.color }}
				>
					{tag.label}
				</span>
			</Conditional>
		))}
	</div>
);

export default Tags;
