import { IconHeart, IconLoader2 } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';

import Button from '@/components/Button/Button.tsx';
import { Item } from '@/customTypes/Item';
import { useProfileQuery } from '@/stores/ProfileHooks.ts';
import {
	useAddToWishlistMutation,
	useFavoritesQuery,
	useRemoveFromWishlistMutation,
} from '@/stores/WishListHooks.ts';

import styles from './AddToFavoritesButton.module.css';

// TODO error handling
export const AddToFavoritesButton: React.FC<{ item?: Item }> = ({ item }) => {
	const favorites = useFavoritesQuery();
	const onFavorites = useMemo(
		() => favorites?.items.find(it => it.item.internalId === item?.internalId),
		[favorites?.items, item?.internalId],
	);
	const addToWishlist = useAddToWishlistMutation();
	const userID = useProfileQuery(user => user?.internalid);
	const removeFromWishlist = useRemoveFromWishlistMutation();
	const [forceLoading, setForceLoading] = useState(false);
	const loading = !item || forceLoading;

	return (
		<Button
			className={`${styles.button} ${onFavorites ? styles.active : ''} ${loading ? styles.loading : ''}`}
			disabled={loading || !favorites}
			onClick={() => {
				setForceLoading(true);
				if (onFavorites) {
					removeFromWishlist
						.mutateAsync({
							internalid: onFavorites.internalid,
							listID: favorites!.internalid,
							user: userID!,
						})
						.catch(console.error)
						.finally(() => setForceLoading(false));
				} else {
					addToWishlist
						.mutateAsync({
							item: item!,
							listID: favorites!.internalid,
							quantity: 1,
							userID: userID!,
						})
						.catch(console.error)
						.finally(() => setForceLoading(false));
				}
			}}
			variant="outline"
		>
			{loading ? (
				<>
					<IconLoader2 className="loader" />
					<span>Loading...</span>
				</>
			) : (
				<>
					<IconHeart />
					<span>{onFavorites ? 'Remove from Favorites' : 'Add to Favorites'}</span>
				</>
			)}
		</Button>
	);
};

export default AddToFavoritesButton;
