import { IconLoader2, IconShoppingBag, IconX } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';

import LogoColor from '@/assets/logo-color.svg';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import HeaderLoginButton from '@/components/HeaderLoginButton/HeaderLoginButton.tsx';
import MenuBar from '@/components/MenuBar/MenuBar';
import MenuToggle from '@/components/MenuToggle/MenuToggle';
import SearchBar from '@/components/SearchBar/SearchBar';
import { useStateStore } from '@/stores';
import { useOrderLoading, useOrderQuery } from '@/stores/OrderHooks.ts';

import styles from './Header.module.css';

const Header = () => {
	const lineCount = useOrderQuery(order => order?.lines.length) ?? 0;
	const loadingCart = useOrderLoading();
	const cartOpen = useStateStore(state => state.state.cartOpen);
	const setState = useStateStore(state => state.setState);

	return (
		<header className={styles.header}>
			<div className="containerGrid">
				<div className={styles.containerWrapper}>
					<MenuToggle className={styles.menuToggle} id="menu-button" />
					<Link className={styles.logo} params={{}} search={{}} to="/">
						<img alt="logo" src={LogoColor} />
					</Link>
					<Breakpoint minWidth={992}>
						<SearchBar className={styles.searchBarDesktop} />
					</Breakpoint>
					<div style={{ alignItems: 'center', display: 'flex' }}>
						<HeaderLoginButton />
						<button
							aria-label="Cart"
							className={styles.cartButton}
							id="cart-button"
							onClick={() => setState({ cartOpen: !cartOpen })}
						>
							{cartOpen ? <IconX /> : <IconShoppingBag />}
							{loadingCart && !lineCount ? (
								<IconLoader2 className="loader" />
							) : (
								<label>{lineCount}</label>
							)}
						</button>
					</div>
				</div>
			</div>
			<Breakpoint maxWidthExclude={992}>
				<div className={styles.searchBarMobile}>
					<SearchBar />
				</div>
			</Breakpoint>
			<Breakpoint minWidth={992}>
				<MenuBar />
			</Breakpoint>
		</header>
	);
};

export default Header;
